/* tslint:disable */
/* eslint-disable */
/**
 * Notarize Genie
 * Notarize Genie API Integration
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface OptOutDto
 */
export interface OptOutDto {
    /**
     * 
     * @type {string}
     * @memberof OptOutDto
     */
    reason: string;
}

/**
 * Check if a given object implements the OptOutDto interface.
 */
export function instanceOfOptOutDto(value: object): value is OptOutDto {
    if (!('reason' in value) || value['reason'] === undefined) return false;
    return true;
}

export function OptOutDtoFromJSON(json: any): OptOutDto {
    return OptOutDtoFromJSONTyped(json, false);
}

export function OptOutDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): OptOutDto {
    if (json == null) {
        return json;
    }
    return {
        
        'reason': json['reason'],
    };
}

export function OptOutDtoToJSON(value?: OptOutDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'reason': value['reason'],
    };
}

