import { Box, Flex, Text } from "@chakra-ui/react";
import { useEffect, useMemo, useReducer, useState } from "react";
import { io } from "socket.io-client";
import { useActiveSessionStore } from "../../../store/activeSessionStore";
import { WS_URL } from "../../../utils/constants";
import { useAuth } from "../../../utils/use-auth";
import { getFileUrl, getSessionDetails } from "../../../utils/utils-apis";
import SessionPdfEditor from "../../SessionPdfEditor/SessionPdfEditor";
import { useParams } from "react-router-dom";
import TasksButton from "./TaskButton";
import { ActiveSessionApi, ESignSessionV2Api } from "../../../api";
import useNotification from "../../../utils/useNotification";

const PrepareDocPage = ({
  from = "edit",
}: {
  from?: "edit" | "notary" | "signer" | "business";
}) => {
  const { sessionId } = useParams();
  const { configuration } = useAuth();
  const sessionInfo = useActiveSessionStore((state) => state.sessionInfo);
  const setSessionInfo = useActiveSessionStore((state) => state.setSessionInfo);
  const clearSessionInfo = useActiveSessionStore(
    (state) => state.clearSessionInfo
  );
  const activeSessionId = useActiveSessionStore(
    (state) => state.activeSessionId
  );
  const { notification } = useNotification();
  const [finishing, setFinishing] = useState(false);

  const socket = useMemo(() => {
    return io(WS_URL, {
      query: {
        token: localStorage.getItem("accessToken"),
      },
    });
  }, []);

  const [notaryUrlData, dispatch] = useReducer(
    (state: any, { type, payload }: any) => {
      if (type === "initialize") {
        return {
          ...payload,
        };
      }
    },
    {
      notarySignatureUrl: "",
      notaryInitialsUrl: "",
      notaryStampUrl: "",
    }
  );

  useEffect(() => {
    if (sessionInfo?.notary) {
      Promise.all([
        getFileUrl(configuration, sessionInfo?.notary.signature!),
        getFileUrl(configuration, sessionInfo?.notary.initials!),
        getFileUrl(configuration, sessionInfo?.notary.seal!),
      ]).then((r) => {
        dispatch({
          type: "initialize",
          payload: {
            notarySignatureUrl: r[0],
            notaryInitialsUrl: r[1],
            notaryStampUrl: r[2],
          },
        });
      });
    }
  }, [sessionInfo?.notary]);

  useEffect(() => {
    if (sessionId) {
      getSessionDetails(configuration, Number(sessionId)).then((res) => {
        setSessionInfo(res);
      });
    }
  }, [sessionId]);

  const handleEndMeeting = () => {
    return new ESignSessionV2Api(configuration).eSignSessionControllerEndSession({
      sessionId: Number(sessionId),
    })
        .then((res) => {
          notification("success", "Meeting Ended Successfully");
          setFinishing(false);
          window.location.href = `/`;
        })
        .catch((err) => {
          console.log("err prepareDocPage", err);
          setFinishing(false);
        });
  };

  return (
    <Box height={"100vh"} overflow={"auto"} my={"auto"}>
      <Flex
        justifyContent={"space-between"}
        alignItems={"center"}
        paddingInline={2}
        style={{
          position: "sticky",
          top: 0,
          zIndex: 11,
          background: "white",
        }}
      >
        <Text
          as={"p"}
          fontSize="20px"
          color="black"
          style={{ fontWeight: 600 }}
        >
          E-Sign Document Session
        </Text>

        <TasksButton
          isFinishing={finishing}
          onFinish={() => {
            setFinishing(true);
            new ESignSessionV2Api(configuration)
              .eSignSessionControllerSigDocument({
                sessionId: Number(sessionId),
              })
              .then(async (res) => {
                await handleEndMeeting();
                clearSessionInfo();
              })
              .catch((err) => {
                console.log("err", err);
                setFinishing(false);
              });
          }}
        />
      </Flex>
      {sessionId ? (
        <SessionPdfEditor
          from={from}
          socket={socket}
          sessionId={sessionId?.toString()}
          notaryUrlData={notaryUrlData}
        />
      ) : null}
    </Box>
  );
};

export default PrepareDocPage;
