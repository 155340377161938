/* tslint:disable */
/* eslint-disable */
/**
 * Notarize Genie
 * Notarize Genie API Integration
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ESignSessionDTO
 */
export interface ESignSessionDTO {
    /**
     * 
     * @type {number}
     * @memberof ESignSessionDTO
     */
    notary?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof ESignSessionDTO
     */
    documents?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof ESignSessionDTO
     */
    signers: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof ESignSessionDTO
     */
    notaryNote?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ESignSessionDTO
     */
    isESignSession?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ESignSessionDTO
     */
    primarySignerUserId?: number;
    /**
     * 
     * @type {string}
     * @memberof ESignSessionDTO
     */
    primarySignerName?: string;
}

/**
 * Check if a given object implements the ESignSessionDTO interface.
 */
export function instanceOfESignSessionDTO(value: object): value is ESignSessionDTO {
    if (!('signers' in value) || value['signers'] === undefined) return false;
    return true;
}

export function ESignSessionDTOFromJSON(json: any): ESignSessionDTO {
    return ESignSessionDTOFromJSONTyped(json, false);
}

export function ESignSessionDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): ESignSessionDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'notary': json['notary'] == null ? undefined : json['notary'],
        'documents': json['documents'] == null ? undefined : json['documents'],
        'signers': json['signers'],
        'notaryNote': json['notaryNote'] == null ? undefined : json['notaryNote'],
        'isESignSession': json['isESignSession'] == null ? undefined : json['isESignSession'],
        'primarySignerUserId': json['primarySignerUserId'] == null ? undefined : json['primarySignerUserId'],
        'primarySignerName': json['primarySignerName'] == null ? undefined : json['primarySignerName'],
    };
}

export function ESignSessionDTOToJSON(value?: ESignSessionDTO | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'notary': value['notary'],
        'documents': value['documents'],
        'signers': value['signers'],
        'notaryNote': value['notaryNote'],
        'isESignSession': value['isESignSession'],
        'primarySignerUserId': value['primarySignerUserId'],
        'primarySignerName': value['primarySignerName'],
    };
}

