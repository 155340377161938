import { Socket } from "socket.io-client";
import { PDFPageProxy } from "pdfjs-dist";
import { useEffect, useReducer, useRef } from "react";
import { useDisclosure } from "@chakra-ui/react";
import { Notary } from "../../../api";
import CheckBox from "../tools/Checkbox";
import Scribble from "../tools/Scribble";
import Rectangle from "../tools/Rectangle";
import ImageCanvas from "../tools/ImageCanvas";
import GetSignature from "../tools/GetSignature";
import TextAreaTool from "../tools/TextAreaTool";
import TextComponent from "../tools/TextComponent";
import useAuditActions from "../lib/useAuditActions";
import DocumentTypeModal from "../modals/DocumentTypeModal";
import { useDimensionsStore } from "../../../store/dimensions";
import {
  EventType,
  ObjectData,
  useAuditLogsStore,
} from "../../../store/auditLogs";
import { INotaryUrlData } from "../../../store/zustandType";
import LabelInput from "../tools/labelInput";

type Props = {
  page: PDFPageProxy;
  index: number;
  setSelectedPage: () => void;
  pageNumber: number;
  sessionId: string;
  socket: Socket;
  from: string;
  notaryUrlData: INotaryUrlData;
  pagePosition: {
    x: number;
    y: number;
    scale: number;
  };
};

const PdfPageData = ({
  page,
  index,
  setSelectedPage,
  pageNumber,
  from,
  sessionId,
  socket,
  pagePosition,
  notaryUrlData,
}: Props) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const payloadRef = useRef<Partial<ObjectData>>();
  const droppableRef = useRef<HTMLDivElement>(null);
  const canvasRef = useRef<HTMLCanvasElement | null>(null);
  const setRatio = useDimensionsStore((state) => state.setRatio);
  const auditLogs = useAuditLogsStore((state) => state.auditLogs?.[index]);

  const { notarySignatureUrl, notaryInitialsUrl, notaryStampUrl } =
    notaryUrlData;

  const {
    getSignatureObject,
    placeCheckboxObject,
    placeDateObject,
    placeImageObject,
    placeRectangleObject,
    placeTextObject,
    placeScibbleObject,
    placeDisclosureObject,
    getLabelInput,
  } = useAuditActions({
    sessionId,
    socket,
    notarySignatureUrl,
    notaryInitialsUrl,
    notaryStampUrl,
  });

  useEffect(() => {
    if (canvasRef.current && page) {
      const context = canvasRef.current?.getContext("2d");
      const viewport = page.getViewport({ scale: 1 });
      canvasRef.current.width = viewport.width;
      canvasRef.current.height = viewport.height;
      canvasRef.current.style.width = "100%";
      canvasRef.current.style.height = "100%";
      setRatio(
        (viewport.rotation === 270 || viewport.rotation === 90
          ? page.view[3]
          : page.view[2]) / canvasRef.current.clientWidth
      );
      try {
        const renderTask = page?.render({
          canvasContext: context as Object,
          viewport,
        });
        return () => {
          renderTask?.cancel();
        };
      } catch (e) {
        console.info("Page again rendered", e);
      }
    }
  }, [page]);

  // useEffect(() => {
  //   if (notaryDetails) {
  //     Promise.all([
  //       getFileUrl(configuration, notaryDetails.signature!),
  //       getFileUrl(configuration, notaryDetails.initials!),
  //       getFileUrl(configuration, notaryDetails.seal!),
  //     ]).then((r) => {
  //       dispatch({
  //         type: "initialize",
  //         payload: {
  //           notarySignatureUrl: r[0],
  //           notaryInitialsUrl: r[1],
  //           notaryStampUrl: r[2],
  //         },
  //       });
  //     });
  //   }
  // }, [notaryDetails]);

  return (
    <div
      ref={droppableRef}
      style={{
        position: "relative",
        width: "100%",
      }}
      id={`page${pageNumber}`}
      onDragOver={(event) => {
        event.stopPropagation();
        event.preventDefault();
      }}
      onDrop={(e) => {
        e.preventDefault();
        try {
          const actionToPerform = JSON.parse(
            e.dataTransfer.getData("application/json")
          );
          const rect: Pick<DOMRect, "left" | "top" | "width"> =
            droppableRef.current
              ? droppableRef.current.getBoundingClientRect()
              : { left: 0, top: 0, width: 0 };
          const ratio = canvasRef.current
            ? canvasRef.current.width / rect.width
            : 1;
          setRatio(ratio * pagePosition.scale);
          switch (actionToPerform.action) {
            case "placeTextObject":
              placeTextObject({
                payload: {
                  ...actionToPerform.payload,
                  x: e.clientX - rect.left,
                  y: e.clientY - rect.top,
                  ratio,
                },
                selectedPage: pageNumber,
                scale: pagePosition.scale,
              });
              break;
            case "placeDisclosureObject":
              placeDisclosureObject({
                payload: {
                  ...actionToPerform.payload,
                  x: e.clientX - rect.left,
                  y: e.clientY - rect.top,
                  ratio,
                },
                selectedPage: pageNumber,
                scale: pagePosition.scale,
              });
              break;
            case "placeCheckboxObject":
              placeCheckboxObject({
                payload: {
                  ...actionToPerform.payload,
                  x: e.clientX - rect.left,
                  y: e.clientY - rect.top,
                  ratio,
                },
                selectedPage: pageNumber,
                scale: pagePosition.scale,
              });
              break;
            case "getSignatureObject":
              getSignatureObject({
                payload: {
                  ...actionToPerform.payload,
                  x: e.clientX - rect.left,
                  y: e.clientY - rect.top,
                  ratio,
                },
                selectedPage: pageNumber,
                scale: pagePosition.scale,
              });
              break;
            case "getLabelInput":
              getLabelInput({
                payload: {
                  ...actionToPerform.payload,
                  x: e.clientX - rect.left,
                  y: e.clientY - rect.top,
                  ratio,
                },
                selectedPage: pageNumber,
                scale: pagePosition.scale,
              });
              break;
            case "placeDateObject":
              placeDateObject({
                payload: {
                  ...actionToPerform.payload,
                  x: e.clientX - rect.left,
                  y: e.clientY - rect.top,
                  ratio,
                },
                selectedPage: pageNumber,
                scale: pagePosition.scale,
              });
              break;
            case "placeImageObject":
              if (actionToPerform?.payload?.type === EventType.notaryStamp) {
                onOpen();
                payloadRef.current = {
                  ...actionToPerform.payload,
                  x: e.clientX - rect.left,
                  y: e.clientY - rect.top,
                  ratio,
                };
              } else {
                placeImageObject({
                  payload: {
                    ...actionToPerform.payload,
                    x: e.clientX - rect.left,
                    y: e.clientY - rect.top,
                    ratio,
                  },
                  selectedPage: pageNumber,
                  scale: pagePosition.scale,
                });
              }
              break;
            case "placeRectangleObject":
              placeRectangleObject({
                payload: {
                  ...actionToPerform.payload,
                  x: e.clientX - rect.left,
                  y: e.clientY - rect.top,
                  height: 50,
                  width: 100,
                  ratio,
                },
                selectedPage: pageNumber,
                scale: pagePosition.scale,
              });
              break;

            case "placeScribble":
              placeScibbleObject({
                payload: {
                  ...actionToPerform.payload,
                  x: e.clientX - rect.left,
                  y: e.clientY - rect.top,
                  ratio,
                },
                selectedPage: pageNumber,
                scale: pagePosition.scale,
              });
              break;

            default:
              break;
          }
        } catch (e) {
          console.log(e);
        }
      }}
    >
      <DocumentTypeModal
        isOpen={isOpen}
        onClose={onClose}
        onOpen={onOpen}
        onSelection={(type: "Jurat" | "Acknowlegement") => {
          // handleFormAddition(type)
          if (payloadRef.current) {
            placeImageObject({
              typeOfStamp: type,
              selectedPage: pageNumber,
              payload: { ...payloadRef.current, typeOfStamp: type },
              scale: pagePosition.scale,
            });
            payloadRef.current = undefined;
          }
        }}
      />
      <canvas
        onClick={() => {
          setSelectedPage();
        }}
        ref={canvasRef}
      />

      {auditLogs?.map((object) =>
        object?.type === "text" || object?.type === "date" ? (
          <TextComponent
            key={object.id}
            object={object}
            page={pageNumber}
            from={from}
            scale={pagePosition.scale}
          />
        ) : ["notarySignature", "notaryInitials", "notaryStamp"].includes(
            object.type ?? ""
          ) ? (
          <ImageCanvas
            object={object}
            key={object.id}
            page={pageNumber}
            from={from}
            scale={pagePosition.scale}
          />
        ) : object?.type === "rectangle" ? (
          <Rectangle
            key={object.id}
            object={object}
            page={pageNumber}
            from={from}
            scale={pagePosition.scale}
          />
        ) : object?.type === "checkbox" ? (
          <CheckBox
            key={object.id}
            object={object}
            page={pageNumber}
            from={from}
            scale={pagePosition.scale}
          />
        ) : object?.type === "scribble" ? (
          <Scribble
            key={object.id}
            object={object}
            page={pageNumber}
            from={from}
            scale={pagePosition.scale}
          />
        ) : object?.type === EventType.disclosure ? (
          <TextAreaTool
            from={from}
            object={object}
            page={pageNumber}
            scale={pagePosition.scale}
            key={object.id}
          />
        ) : ["signerSignature", "witnessSignature", "signerInitials"].includes(
            object.type ?? ""
          ) ? (
          <GetSignature
            object={object}
            page={pageNumber}
            from={from}
            key={object.id}
            scale={pagePosition.scale}
          />
        ) : object?.type === EventType.labelInput ? (
          <LabelInput
            key={object.id}
            object={object}
            page={pageNumber}
            from={from}
            scale={pagePosition.scale}
          />
        ) : (
          ""
        )
      )}
    </div>
  );
};

export default PdfPageData;
