import { BrowserRouter, Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import BusinessWaiting from "./component/onDemands/Mediator/BusinessWaiting";
import MediatorMeeting from "./component/onDemands/Mediator/MediatorMeeting";
import MeetingCompleted from "./component/onDemands/Notary/MeetingCompleted";
import SignerWaiting from "./component/onDemands/Signer/SignerWaiting";
import SignerMeeting from "./component/onDemands/Signer/SignerMeeting";
import WitnessMeeting from "./component/onDemands/Witness/WitnessMeeting";
import FullScreenLayout from "./layout/FullScreenLayout";
import BusinessProfile from "./pages/Business/BusinessProfile/BusinessProfile";
import BusinessPlans from "./pages/Business/Plans/BusinessPlans";
import ChangePassword from "./pages/ChangePassword/ChangePassword";
import NotaryPlans from "./pages/Notary/NotaryPlans/NotaryPlans";
import PaymentInfo from "./pages/Payment/PaymentInfo";
import SignerProfile from "./pages/Signer/SignerProfile/SignerProfile";
import PrivateRoutes from "./routes/PrivateRoutes";
import {
  businessRoutes,
  notaryPaths,
  notaryRoutes,
  publicRoutes,
  signerRoutes,
} from "./routes/routes";
import "./App.css";
import NotaryMeeting from "./component/onDemands/Notary/NotaryMeeting";
import Logout from "./pages/Auth/Components/Logout";
import ThankyouPage from "./pages/ThankyouPage";
import { useEffect } from "react";
import { OpenReplay } from "./utils/OpenReplay";
import RequestSuccessPage from "./pages/RequestSuccess";
import Feedback from "./component/onDemands/Signer/Feedback";
import WitnessWaiting from "./component/onDemands/Witness/WitnessWaiting";
import { jwtDecoder } from "./utils/utils-functions";
import PrepareDocPage from "./component/Sessions/esignSession/PrepareDocPage";

export const App = () => {
  const params = new URL(window.location.href).searchParams;
  const token = params.get("access-token");
  const localStorageRole = localStorage.getItem("role");
  let role = "";
  role = localStorageRole != null ? localStorageRole : "";

  if (token != null) {
    const { userId, role: userRole } = jwtDecoder(token);
    localStorage.setItem("UserID", userId);
    localStorage.setItem("role", userRole);
    role = userRole;
  }

  useEffect(() => {
    OpenReplay.setUser(localStorage.getItem("email") as string);
  }, []);

  return (
    <>
      <ToastContainer
        position="top-left"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
      />
      <BrowserRouter>
        <Routes>
          <Route element={<FullScreenLayout />}>
            <Route path="/logout" element={<Logout />} />
            <Route path="/change-password" element={<ChangePassword />} />
            <Route path="/payment" element={<PaymentInfo />} />
            <Route path="/notary/plans" element={<NotaryPlans />} />
            {role === "notary" && (
              <>
                <Route
                  path={`${notaryPaths.notaryOndemand}/notary-session`}
                  element={<NotaryMeeting />}
                />
                <Route
                  path={`${notaryPaths.notaryOndemand}/meeting-complete/:activeSessionId`}
                  element={<MeetingCompleted />}
                />
                <Route
                path={`${notaryPaths.prepareSession}/:sessionId`}
                element={<PrepareDocPage />}
                />
              </>
            )}

            <Route path="/signer/profile" element={<SignerProfile />} />
            <Route path="/signer/esign-session/:sessionId" element={<PrepareDocPage from="signer" />} />

            <Route
              path="/signer/on-demand/feedback/:sessionId"
              element={<Feedback />}
            />

            <Route
              path="/signer/on-demand/waiting-for-notary/"
              element={<SignerWaiting />}
            />

            <Route
              path="/signer/on-demand/meeting"
              element={<SignerMeeting />}
            />

            <Route
              path="/business/waiting-for-notary/"
              element={<BusinessWaiting />}
            />

            <Route
              path="/business/mediator-session"
              element={<MediatorMeeting />}
            />

            <Route
              path={`/notary/on-demand/witness-session`}
              element={<WitnessMeeting />}
            />

            <Route
              path="/notary/on-demand/waiting-for-notary/"
              element={<WitnessWaiting />}
            />

            <Route path="/thank-you" element={<ThankyouPage />} />
            <Route path="/request-success" element={<RequestSuccessPage />} />

            {role === "business" && (
              <>
                <Route path="/business/plans" element={<BusinessPlans />} />
                <Route path="/business/profile" element={<BusinessProfile />} />
              </>
            )}
          </Route>
          <Route element={<PrivateRoutes />}>
            {role === "notary" && (
              <>
                {notaryRoutes.map((route) =>
                  route?.route ? (
                    <Route
                      path={route.route}
                      element={route.component}
                      key={route.route}
                    >
                      {route.children?.map((childRoute) =>
                        childRoute.route ? (
                          <Route
                            path={childRoute.route}
                            element={childRoute.component}
                            key={childRoute.route}
                          >
                            {childRoute.children?.map((grandChildRoute) => (
                              <Route
                                path={grandChildRoute.route}
                                element={grandChildRoute.component}
                                key={grandChildRoute.route}
                              />
                            ))}
                          </Route>
                        ) : (
                          <></>
                        )
                      )}
                    </Route>
                  ) : (
                    <></>
                  )
                )}
              </>
            )}

            {role === "signer" && (
              <>
                {signerRoutes.map((route) =>
                  route?.route ? (
                    <Route
                      path={route.route}
                      element={route.component}
                      key={route.route}
                    >
                      {route.children?.map((childRoute) =>
                        childRoute.route ? (
                          <Route
                            path={childRoute.route}
                            element={childRoute.component}
                            key={childRoute.route}
                          >
                            {childRoute.children?.map((grandChildRoute) => (
                              <Route
                                path={grandChildRoute.route}
                                element={grandChildRoute.component}
                                key={grandChildRoute.route}
                              />
                            ))}
                          </Route>
                        ) : (
                          <></>
                        )
                      )}
                    </Route>
                  ) : (
                    <></>
                  )
                )}
              </>
            )}

            {role === "business" && (
              <>
                {businessRoutes.map((route) =>
                  route?.route ? (
                    <Route
                      path={route.route}
                      element={route.component}
                      key={route.route}
                    >
                      {route.children?.map((childRoute) =>
                        childRoute.route ? (
                          <Route
                            path={childRoute.route}
                            element={childRoute.component}
                            key={childRoute.route}
                          >
                            {childRoute.children?.map((grandChildRoute) => (
                              <Route
                                path={grandChildRoute.route}
                                element={grandChildRoute.component}
                                key={grandChildRoute.route}
                              ></Route>
                            ))}
                          </Route>
                        ) : (
                          <></>
                        )
                      )}
                    </Route>
                  ) : (
                    <></>
                  )
                )}
              </>
            )}
          </Route>

          <Route>
            {publicRoutes.map((route) => (
              <Route
                path={route.route}
                element={route.component}
                key={route.route}
              />
            ))}
          </Route>
        </Routes>
      </BrowserRouter>
    </>
  );
};
