/* tslint:disable */
/* eslint-disable */
/**
 * Notarize Genie
 * Notarize Genie API Integration
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ActiveSession,
  ESignSessionDTO,
  OptOutDto,
  Session,
  SignDocResponse,
} from '../models/index';
import {
    ActiveSessionFromJSON,
    ActiveSessionToJSON,
    ESignSessionDTOFromJSON,
    ESignSessionDTOToJSON,
    OptOutDtoFromJSON,
    OptOutDtoToJSON,
    SessionFromJSON,
    SessionToJSON,
    SignDocResponseFromJSON,
    SignDocResponseToJSON,
} from '../models/index';

export interface ESignSessionControllerCreateRequest {
    eSignSessionDTO: ESignSessionDTO;
}

export interface ESignSessionControllerCreateActiveSessionRequest {
    sessionId: number;
}

export interface ESignSessionControllerEndSessionRequest {
    sessionId: number;
}

export interface ESignSessionControllerOptOutRequest {
    sessionId: number;
    optOutDto: OptOutDto;
}

export interface ESignSessionControllerSigDocumentRequest {
    sessionId: number;
}

/**
 * 
 */
export class ESignSessionV2Api extends runtime.BaseAPI {

    /**
     */
    async eSignSessionControllerCreateRaw(requestParameters: ESignSessionControllerCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Session>> {
        if (requestParameters['eSignSessionDTO'] == null) {
            throw new runtime.RequiredError(
                'eSignSessionDTO',
                'Required parameter "eSignSessionDTO" was null or undefined when calling eSignSessionControllerCreate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/e-sign/session`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ESignSessionDTOToJSON(requestParameters['eSignSessionDTO']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SessionFromJSON(jsonValue));
    }

    /**
     */
    async eSignSessionControllerCreate(requestParameters: ESignSessionControllerCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Session> {
        const response = await this.eSignSessionControllerCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async eSignSessionControllerCreateActiveSessionRaw(requestParameters: ESignSessionControllerCreateActiveSessionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ActiveSession>> {
        if (requestParameters['sessionId'] == null) {
            throw new runtime.RequiredError(
                'sessionId',
                'Required parameter "sessionId" was null or undefined when calling eSignSessionControllerCreateActiveSession().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/e-sign/active-session/{sessionId}`.replace(`{${"sessionId"}}`, encodeURIComponent(String(requestParameters['sessionId']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ActiveSessionFromJSON(jsonValue));
    }

    /**
     */
    async eSignSessionControllerCreateActiveSession(requestParameters: ESignSessionControllerCreateActiveSessionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ActiveSession> {
        const response = await this.eSignSessionControllerCreateActiveSessionRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async eSignSessionControllerEndSessionRaw(requestParameters: ESignSessionControllerEndSessionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['sessionId'] == null) {
            throw new runtime.RequiredError(
                'sessionId',
                'Required parameter "sessionId" was null or undefined when calling eSignSessionControllerEndSession().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/e-sign/end-esign-session/{sessionId}`.replace(`{${"sessionId"}}`, encodeURIComponent(String(requestParameters['sessionId']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async eSignSessionControllerEndSession(requestParameters: ESignSessionControllerEndSessionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.eSignSessionControllerEndSessionRaw(requestParameters, initOverrides);
    }

    /**
     */
    async eSignSessionControllerGetBusinessSessionsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Session>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/e-sign/business/sessions`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SessionFromJSON));
    }

    /**
     */
    async eSignSessionControllerGetBusinessSessions(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Session>> {
        const response = await this.eSignSessionControllerGetBusinessSessionsRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async eSignSessionControllerGetEsignSessionsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Session>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/e-sign/notary/sessions`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SessionFromJSON));
    }

    /**
     */
    async eSignSessionControllerGetEsignSessions(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Session>> {
        const response = await this.eSignSessionControllerGetEsignSessionsRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async eSignSessionControllerGetSignerSessionsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Session>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/e-sign/signer/sessions`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SessionFromJSON));
    }

    /**
     */
    async eSignSessionControllerGetSignerSessions(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Session>> {
        const response = await this.eSignSessionControllerGetSignerSessionsRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async eSignSessionControllerOptOutRaw(requestParameters: ESignSessionControllerOptOutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['sessionId'] == null) {
            throw new runtime.RequiredError(
                'sessionId',
                'Required parameter "sessionId" was null or undefined when calling eSignSessionControllerOptOut().'
            );
        }

        if (requestParameters['optOutDto'] == null) {
            throw new runtime.RequiredError(
                'optOutDto',
                'Required parameter "optOutDto" was null or undefined when calling eSignSessionControllerOptOut().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/e-sign/optout/{sessionId}`.replace(`{${"sessionId"}}`, encodeURIComponent(String(requestParameters['sessionId']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: OptOutDtoToJSON(requestParameters['optOutDto']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async eSignSessionControllerOptOut(requestParameters: ESignSessionControllerOptOutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.eSignSessionControllerOptOutRaw(requestParameters, initOverrides);
    }

    /**
     */
    async eSignSessionControllerSigDocumentRaw(requestParameters: ESignSessionControllerSigDocumentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SignDocResponse>> {
        if (requestParameters['sessionId'] == null) {
            throw new runtime.RequiredError(
                'sessionId',
                'Required parameter "sessionId" was null or undefined when calling eSignSessionControllerSigDocument().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/e-sign/sign-doc/{sessionId}`.replace(`{${"sessionId"}}`, encodeURIComponent(String(requestParameters['sessionId']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SignDocResponseFromJSON(jsonValue));
    }

    /**
     */
    async eSignSessionControllerSigDocument(requestParameters: ESignSessionControllerSigDocumentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SignDocResponse> {
        const response = await this.eSignSessionControllerSigDocumentRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
