import { create } from "zustand";
import { Socket } from "socket.io-client";
import { devtools } from "zustand/middleware";
import { AuditLogs } from "../api";
import { OpenReplay } from "../utils/OpenReplay";
import { addForm } from "../utils/pdf-utils/addForm";
import { useCurrentDocStore } from "./sessionDocStore";
import { removeForm } from "../utils/pdf-utils/removeForm";
import {
  addAuditLogUtil,
  deleteAuditLogUtil,
  updateAuditLogUtil,
} from "./auditLogsUtils";

export enum EventType {
  notarySignature = "notarySignature",
  notaryInitials = "notaryInitials",
  notaryStamp = "notaryStamp",
  text = "text",
  date = "date",
  rectangle = "rectangle",
  checkbox = "checkbox",
  signerSignatureRoi = "signerSignatureRoi",
  signerSignature = "signerSignature",
  witnessSignature = "witnessSignature",
  docUploaded = "documnent",
  signerInitials = "signerInitials",
  scribble = "scribble",
  formRemoved = "formRemoved",
  empty = "",
  document = "document",
  disclosure = "disclosure",
  navigatedToSelectedPage = "changePage",
  navigatedToDifferentDoc = "changeDocumnent",
  labelInput = "labelInput",
}

export enum EventAction {
  add = "add",
  update = "update",
  delete = "delete",
  addedForm = "addedForm",
  docUploaded = "upload",
  remove = "remove",
  navigatedToDifferentDoc = "change",
  newDocAdded = "newDocAdded",
  navigatedToSelectedPage = "navigate",
}

export type ObjectData = {
  id: string;
  text: string;
  type: string;
  lines: [];
  size: number;
  width: number;
  lineHeight: number;
  fontFamily: string;
  x: number;
  y: number;
  height: number;
  // payload: any;
  file: any;
  url: string;
  userId: number;
  color: string;
  checked: boolean;
  toBeSignedBy?: string;
  toBeSignedByUserId?: number;
  typeOfStamp?: string;
  scale?: number;
  ratio: number;
  zoomScale?: number;
};

export type PartialObjectData = Partial<ObjectData>;

interface IUseAuditLogsStore {
  scale: number;
  setScale: (newScale: number) => void;
  sessionId: string;
  socket?: Socket;
  setSessionId: (sessionId: string) => void;
  setSocket: (socket: Socket) => void;
  auditLogs: PartialObjectData[][];
  setAuditLogs: (auditLogs: PartialObjectData[][]) => void;
  addAuditLog: ({
    selectedPage,
    payload,
    userId,
    docId,
  }: {
    selectedPage: number;
    payload: PartialObjectData;
    userId?: number;
    docId: number;
  }) => void;

  deleteAuditLog: ({
    id,
    selectedPage,
    type,
    payload,
    userId,
  }: {
    id: string;
    selectedPage: number;
    type: string;
    payload: PartialObjectData;
    userId?: number;
  }) => void;

  updateAuditLog: ({
    auditLog,
    id,
    selectedPage,
    type,
  }: {
    id: string;
    selectedPage: number;
    auditLog: PartialObjectData;
    type: string;
  }) => void;
  removeAuditLog: ({ pageNumber }: { pageNumber: number }) => void;
  initialize: (data: AuditLogs[]) => void;
}

export const useAuditLogsStore = create<IUseAuditLogsStore>()(
  devtools(
    OpenReplay.createLogger("auditLogs-store")(
      // @ts-ignore
      (set, get) => ({
        scale: 1,
        setScale: (newScale: number) =>
          set((state: any) => ({
            ...state,
            scale: newScale,
          })),
        sessionId: "",
        socket: undefined,
        setSessionId: (sessionId: string) =>
          set((state: any) => ({
            ...state,
            sessionId,
          })),
        setSocket: (socket: Socket) =>
          set((state: any) => ({
            ...state,
            socket,
          })),
        auditLogs: [],
        setAuditLogs: (auditLogs: PartialObjectData[][]) => {
          set({
            auditLogs,
          });
        },
        addAuditLog: ({
          selectedPage,
          payload,
          userId,
          docId,
        }: {
          selectedPage: number;
          payload: PartialObjectData;
          userId?: number;
          docId: number;
        }) => {
          if (!get().auditLogs[selectedPage]) return;
          const index = get().auditLogs[selectedPage].findIndex(
            (a: any) => a.id === payload.id
          );
          if (index !== -1) {
            get().updateAuditLog({
              auditLog: payload,
              id: get().auditLogs[selectedPage][index].id,
              selectedPage,
            });
            return;
          }
          if (payload?.type === EventType.text) {
            set((state: any) => ({
              ...state,
              auditLogs: [
                ...get().auditLogs.slice(0, selectedPage),
                [
                  ...get().auditLogs[selectedPage],
                  {
                    ...payload,
                    text: payload.text,
                    type: "text",
                  },
                ],
                ...get().auditLogs.slice(
                  selectedPage + 1,
                  get().auditLogs.length
                ),
              ],
            }));
          } else if (payload?.type === EventType.disclosure) {
            set((state: any) => ({
              ...state,
              auditLogs: [
                ...get().auditLogs.slice(0, selectedPage),
                [
                  ...get().auditLogs[selectedPage],
                  {
                    ...payload,
                    text: payload.text,
                    type: "disclosure",
                  },
                ],
                ...get().auditLogs.slice(
                  selectedPage + 1,
                  get().auditLogs.length
                ),
              ],
            }));
          } else if (payload?.type === EventType.date) {
            set((state: any) => ({
              ...state,
              auditLogs: [
                ...get().auditLogs.slice(0, selectedPage),
                [
                  ...get().auditLogs[selectedPage],
                  {
                    ...payload,
                    text: new Intl.DateTimeFormat("en-US")
                      .format(new Date())
                      .toString(),
                    type: "date",
                  },
                ],
                ...get().auditLogs.slice(
                  selectedPage + 1,
                  get().auditLogs.length
                ),
              ],
            }));
          } else if (payload?.type === EventType.signerInitials) {
            set((state: any) => ({
              ...state,
              auditLogs: [
                ...get().auditLogs.slice(0, selectedPage),
                [
                  ...get().auditLogs[selectedPage],
                  {
                    ...payload,
                    type: "signerInitials",
                  },
                ],
                ...get().auditLogs.slice(
                  selectedPage + 1,
                  get().auditLogs.length
                ),
              ],
            }));
          } else if (payload?.type === EventType.rectangle) {
            set((state: any) => ({
              ...state,
              auditLogs: [
                ...get().auditLogs.slice(0, selectedPage),
                [
                  ...get().auditLogs[selectedPage],
                  {
                    ...payload,
                    type: "rectangle",
                    color: "#ffffff",
                  },
                ],
                ...get().auditLogs.slice(
                  selectedPage + 1,
                  get().auditLogs.length
                ),
              ],
            }));
          } else if (payload?.type === EventType.checkbox) {
            set((state: any) => ({
              ...state,
              auditLogs: [
                ...get().auditLogs.slice(0, selectedPage),
                [
                  ...get().auditLogs[selectedPage],
                  {
                    ...payload,
                    type: "checkbox",
                  },
                ],
                ...get().auditLogs.slice(
                  selectedPage + 1,
                  get().auditLogs.length
                ),
              ],
            }));
          } else if (payload?.type === EventType.signerSignature) {
            set((state: any) => ({
              ...state,
              auditLogs: [
                ...get().auditLogs.slice(0, selectedPage),
                [
                  ...get().auditLogs[selectedPage],
                  {
                    ...payload,
                    type: "signerSignature",
                  },
                ],
                ...get().auditLogs.slice(
                  selectedPage + 1,
                  get().auditLogs.length
                ),
              ],
            }));
          } else if (payload?.type === EventType.scribble) {
            set((state: any) => ({
              ...state,
              auditLogs: [
                ...get().auditLogs.slice(0, selectedPage),
                [
                  ...get().auditLogs[selectedPage],
                  {
                    ...payload,
                    type: "scribble",
                  },
                ],
                ...get().auditLogs.slice(
                  selectedPage + 1,
                  get().auditLogs.length
                ),
              ],
            }));
          } else if (payload?.type === EventType.witnessSignature) {
            set((state: any) => ({
              ...state,
              auditLogs: [
                ...get().auditLogs.slice(0, selectedPage),
                [
                  ...get().auditLogs[selectedPage],
                  {
                    ...payload,
                    type: EventType.witnessSignature,
                  },
                ],
                ...get().auditLogs.slice(
                  selectedPage + 1,
                  get().auditLogs.length
                ),
              ],
            }));
          } else if (payload?.type === EventType.notaryStamp) {
            set((state: any) => ({
              ...state,
              auditLogs: [
                ...get().auditLogs.slice(0, selectedPage),
                [
                  ...get().auditLogs[selectedPage],
                  {
                    ...payload,
                    type: payload?.type,
                    url: payload.url,
                    typeOfStamp: payload?.typeOfStamp,
                  },
                ],
                ...get().auditLogs.slice(
                  selectedPage + 1,
                  get().auditLogs.length
                ),
              ],
            }));
          } else if (payload?.type === EventType.notarySignature) {
            set((state: any) => ({
              ...state,
              auditLogs: [
                ...get().auditLogs.slice(0, selectedPage),
                [
                  ...get().auditLogs[selectedPage],
                  {
                    ...payload,
                    type: payload?.type,
                    url: payload.url,
                  },
                ],
                ...get().auditLogs.slice(
                  selectedPage + 1,
                  get().auditLogs.length
                ),
              ],
            }));
          } else if (payload?.type === EventType.notaryInitials) {
            set((state: any) => ({
              ...state,
              auditLogs: [
                ...get().auditLogs.slice(0, selectedPage),
                [
                  ...get().auditLogs[selectedPage],
                  {
                    ...payload,
                    type: payload?.type,
                    url: payload.url,
                  },
                ],
                ...get().auditLogs.slice(
                  selectedPage + 1,
                  get().auditLogs.length
                ),
              ],
            }));
          } else if(payload?.type === EventType.labelInput) {
            set((state: any) => ({
              ...state,
              auditLogs: [
                ...get().auditLogs.slice(0, selectedPage),
                [
                  ...get().auditLogs[selectedPage],
                  {
                    ...payload,
                  },
                ],
                ...get().auditLogs.slice(
                  selectedPage + 1,
                  get().auditLogs.length
                ),
              ],
            }))
          }
        },
        // ________________ delete _______________
        deleteAuditLog: ({ id, selectedPage, type, payload, userId }: any) => {
          set((state: any) => ({
            ...state,
            auditLogs: [
              ...get().auditLogs.slice(0, selectedPage),
              [
                ...get().auditLogs[selectedPage].filter(
                  (el: any) => el.id !== id
                ),
              ],
              ...get().auditLogs.slice(
                selectedPage + 1,
                get().auditLogs.length
              ),
            ],
          }));
        },
        updateAuditLog: ({ auditLog, id, selectedPage, type }: any) => {
          if (get().auditLogs && get().auditLogs[selectedPage]) {
            set((state: any) => ({
              ...state,
              auditLogs: [
                ...get().auditLogs?.slice(0, selectedPage),
                [
                  ...get().auditLogs[selectedPage]?.map((audit: any) =>
                    audit.id === id ? { ...auditLog } : audit
                  ),
                ],
                ...get().auditLogs?.slice(
                  selectedPage + 1,
                  get().auditLogs?.length
                ),
              ],
            }));
          }
        },
        removeAuditLog: ({ pageNumber }: any) => {
          set((state: any) => ({
            ...state,
            auditLogs: [
              ...get().auditLogs.slice(0, pageNumber),
              ...get().auditLogs.slice(pageNumber + 1),
            ],
          }));
        },
        initialize: (data: AuditLogs[]) => {
          const currentDocUrl = useCurrentDocStore.getState().currentDocUrl;
          let auditLogs = get().auditLogs;
          for (const audit of data.sort(
            (a: AuditLogs, b: AuditLogs) =>
              // @ts-ignore
              new Date(a.createdDate) - new Date(b.createdDate)
          )) {
            if (audit.action === "add") {
              auditLogs = addAuditLogUtil(
                audit.payload!,
                auditLogs,
                audit.pageNumber!
              );
            } else if (audit.action === "delete") {
              auditLogs = deleteAuditLogUtil(
                audit.payload!,
                auditLogs,
                audit.pageNumber!
              );
              // deleteAuditLog({
              //   id: audit?.payload?.id,
              //   selectedPage: audit?.pageNumber,
              //   type: audit?.payload?.type,
              //   payload: audit?.payload,
              // });
            } else if (audit.action === "update") {
              auditLogs = updateAuditLogUtil(
                audit.payload!,
                auditLogs,
                audit.pageNumber!
              );
              // updateAuditLog({
              //   auditLog: audit?.payload,
              //   id: audit?.payload?.id,
              //   selectedPage: audit?.pageNumber,
              //   type: audit?.payload?.type,
              // });
            }
            // # TODO : convert this thing into do on later function (when every thing finishes then run this function ,maybe useLayoutEffect will work fine)
            else if (audit?.action === EventAction.addedForm) {
              if (currentDocUrl !== "") {
                // @ts-ignore
                addForm(currentDocUrl, audit?.payload?.typeOfForm).then(
                  (res) => {
                    // setCurrentDocUrl(res.pdfWithFormB64);
                    // setPdf(res.pdfWithFormB64);
                    // #TODO : setPdf accordingly to added forms
                  }
                );
              }
            } else if (audit?.action === EventAction.remove) {
              if (currentDocUrl !== "" && audit?.pageNumber) {
                removeForm(currentDocUrl, audit?.pageNumber).then(
                  (pdfWithRemovedPage) => {
                    // setPdf(pdfWithRemovedPage);
                  }
                );
              }
            }
          }
          set({
            auditLogs,
          });
        },
      }),
      {
        name: "auditLogs-store",
      }
    )
  )
);

export const { subscribe: subscribeAuditLogs } = useAuditLogsStore;
