import { useEffect } from "react";
import NotaryMeetingDetails from "../../../../component/Sessions/Notary/NotaryMeetingDetails";
import { useNotarySessionStepStore } from "../../../../store/notaryStore";
import { useSessionCreate } from "../../../../store/store";
import { useNavigate } from "react-router-dom";
import { notaryPaths } from "../../../../routes/routes";

const NotaryMeetingPage = () => {
  const { setStep, previousStep } = useNotarySessionStepStore();
  const sessionDetail = useSessionCreate((state) => state.sessionDetail);
  const navigate = useNavigate();

  useEffect(() => {
    if (sessionDetail.isEsign) {
      if (previousStep === 1) {
        setStep(3);
        navigate(`${notaryPaths.createSession}/document-upload`)
      } else if (previousStep === 3) {
        setStep(1);
        navigate(`${notaryPaths.createSession}/primary-signer`)
      }
    } else {
      setStep(2);
    }
  }, [setStep, previousStep, sessionDetail.isEsign, navigate]);

  return <NotaryMeetingDetails />;
};

export default NotaryMeetingPage;
