import { Socket } from "socket.io-client";
import { v4 as uuidv4 } from "uuid";
import {
  EventAction,
  EventType,
  PartialObjectData,
  useAuditLogsStore,
} from "../../../store/auditLogs";
import { useDocumentStore } from "../../../store/documentsStore";
import { emitEvent } from "../../../utils/pdf-utils/emitEvent";

type Props = {
  sessionId: string;
  socket?: Socket;
  notarySignatureUrl?: string;
  notaryInitialsUrl?: string;
  notaryStampUrl?: string;
};

type ParamsType = {
  selectedPage: number;
  payload: PartialObjectData;
  scale: number;
  zoomScale?: number;
};

const useAuditActions = ({
  sessionId,
  socket,
  notarySignatureUrl,
  notaryInitialsUrl,
  notaryStampUrl,
}: Props) => {
  const addAuditLog = useAuditLogsStore((state) => state.addAuditLog);
  const docId = useDocumentStore((state) => state.currentDocId);

  const placeTextObject = ({ selectedPage, payload, scale }: ParamsType) => {
    const id = uuidv4();
    addAuditLog({
      selectedPage,
      payload: {
        ...payload,
        id,
        x: payload.x ? payload.x : 0,
        y: payload.y ? payload.y : 0,
        width: payload.width ? payload.width : 0,
        height: payload.height ? payload.height : 0,
        ratio: payload.ratio || 1,
        zoomScale: scale,
      },
      docId,
    });
    emitEvent({
      action: EventAction.add,
      event: EventType.text,
      pageNumber: selectedPage,
      payload: {
        ...payload,
        id,
        x: payload.x ? payload.x : 0,
        y: payload.y ? payload.y : 0,
        width: payload.width ? payload.width : 0,
        height: payload.height ? payload.height : 0,
        ratio: payload.ratio || 1,
        zoomScale: scale,
      },
      sessionId: sessionId,
      socket: socket,
      docId,
    });
  };
  const placeDisclosureObject = ({
    selectedPage,
    payload,
    scale,
  }: ParamsType) => {
    const id = uuidv4();
    addAuditLog({
      selectedPage,
      payload: {
        ...payload,
        id,
        x: payload.x ? payload.x : 0,
        y: payload.y ? payload.y : 0,
        width: payload.width ? payload.width : 0,
        height: payload.height ? payload.height : 0,
        ratio: payload.ratio || 1,
        zoomScale: scale,
      },
      docId,
    });
    emitEvent({
      action: EventAction.add,
      event: EventType.disclosure,
      pageNumber: selectedPage,
      payload: {
        ...payload,
        id,
        x: payload.x ? payload.x : 0,
        y: payload.y ? payload.y : 0,
        width: payload.width ? payload.width : 0,
        height: payload.height ? payload.height : 0,
        ratio: payload.ratio || 1,
        zoomScale: scale,
      },
      sessionId: sessionId,
      socket: socket,
      docId,
    });
  };

  const placeDateObject = ({ selectedPage, payload, scale }: ParamsType) => {
    const id = uuidv4();
    addAuditLog({
      selectedPage,
      payload: {
        ...payload,
        id,
        x: payload.x ? payload.x : payload.x,
        y: payload.y ? payload.y : payload.y,
        width: payload.width ? payload.width : 0,
        height: payload.height ? payload.height : 0,
        ratio: payload.ratio || 1,
        zoomScale: scale,
      },
      docId,
    });
    emitEvent({
      action: EventAction.add,
      event: EventType.date,
      pageNumber: selectedPage,
      payload: {
        ...payload,
        id,
        x: payload.x ? payload.x : 0,
        y: payload.y ? payload.y : 0,
        width: payload.width ? payload.width : 0,
        height: payload.height ? payload.height : 0,
        ratio: payload.ratio || 1,
        zoomScale: scale,
      },
      sessionId: sessionId,
      socket: socket,
      docId,
    });
  };

  const placeRectangleObject = ({
    selectedPage,
    payload,
    scale,
  }: ParamsType) => {
    const id = uuidv4();
    addAuditLog({
      selectedPage,
      payload: {
        ...payload,
        id,
        x: payload.x ? payload.x : payload.x,
        y: payload.y ? payload.y : payload.y,
        width: payload.width ? payload.width : 0,
        height: payload.height ? payload.height : 0,
        ratio: payload.ratio || 1,
        zoomScale: scale,
      },
      docId,
    });
    emitEvent({
      action: EventAction.add,
      event: EventType.rectangle,
      pageNumber: selectedPage,
      payload: {
        ...payload,
        id,
        x: payload.x ? payload.x : 0,
        y: payload.y ? payload.y : 0,
        width: payload.width ? payload.width : 0,
        height: payload.height ? payload.height : 0,
        ratio: payload.ratio || 1,
        zoomScale: scale,
      },
      sessionId: sessionId,
      socket: socket,
      docId,
    });
  };

  const placeCheckboxObject = ({
    selectedPage,
    payload,
    scale,
  }: ParamsType) => {
    const id = uuidv4();
    addAuditLog({
      selectedPage,
      payload: {
        ...payload,
        id,
        x: payload.x ? payload.x : payload.x,
        y: payload.y ? payload.y : payload.y,
        width: payload.width ? payload.width : 0,
        height: payload.height ? payload.height : 0,
        ratio: payload.ratio || 1,
        zoomScale: scale,
      },
      docId,
    });
    emitEvent({
      action: EventAction.add,
      event: EventType.checkbox,
      pageNumber: selectedPage,
      payload: {
        ...payload,
        id,
        x: payload.x ? payload.x : 0,
        y: payload.y ? payload.y : 0,
        width: payload.width ? payload.width : 0,
        height: payload.height ? payload.height : 0,
        ratio: payload.ratio || 1,
        zoomScale: scale,
      },
      sessionId: sessionId,
      socket: socket,
      docId,
    });
  };

  const getSignatureObject = ({ selectedPage, payload, scale }: ParamsType) => {
    const id = uuidv4();
    addAuditLog({
      selectedPage,
      payload: {
        ...payload,
        id,
        x: payload.x ? payload.x : payload.x,
        y: payload.y ? payload.y : payload.y,
        width: payload.width ? payload.width / scale : 100,
        height: payload.height ? payload.height : 60,
        ratio: payload.ratio || 1,
        zoomScale: scale,
      },
      docId,
    });
    emitEvent({
      action: EventAction.add,
      event: payload.type!,
      pageNumber: selectedPage,
      payload: {
        ...payload,
        id,
        x: payload.x ? payload.x : 0,
        y: payload.y ? payload.y : 0,
        width: payload.width ? payload.width : 100,
        height: payload.height ? payload.height : 60,
        ratio: payload.ratio || 1,
        zoomScale: scale,
      },
      sessionId: sessionId,
      socket: socket,
      docId,
    });
  };

  const getLabelInput = ({selectedPage, payload, scale}: ParamsType) =>{
    const id = uuidv4();
    addAuditLog({
      selectedPage,
      payload: {
        ...payload,
        id,
        x: payload.x ? payload.x : payload.x,
        y: payload.y ? payload.y : payload.y,
        width: payload.width ? payload.width : 0,
        height: payload.height ? payload.height : 0,
        ratio: payload.ratio || 1,
        zoomScale: scale,
      },
      docId,
    });
    emitEvent({
      action: EventAction.add,
      event: payload.type!,
      pageNumber: selectedPage,
      payload: {
        ...payload,
        id,
        x: payload.x ? payload.x : 0,
        y: payload.y ? payload.y : 0,
        width: payload.width ? payload.width : 0,
        height: payload.height ? payload.height : 0,
        ratio: payload.ratio || 1,
        zoomScale: scale,
      },
      sessionId: sessionId,
      socket: socket,
      docId,
    });
  }

  const placeScibbleObject = ({ selectedPage, payload, scale }: ParamsType) => {
    const id = uuidv4();
    addAuditLog({
      selectedPage,
      payload: {
        ...payload,
        id,
        x: payload.x ? payload.x : payload.x,
        y: payload.y ? payload.y : payload.y,
        width: payload.width ? payload.width : 100,
        height: payload.height ? payload.height : 60,
        ratio: payload.ratio || 1,
        zoomScale: scale,
      },
      docId,
    });
    emitEvent({
      action: EventAction.add,
      event: payload.type!,
      pageNumber: selectedPage,
      payload: {
        ...payload,
        id,
        x: payload.x ? payload.x : 0,
        y: payload.y ? payload.y : 0,
        width: payload.width ? payload.width : 100,
        height: payload.height ? payload.height : 60,
        ratio: payload.ratio || 1,
        zoomScale: scale,
      },
      sessionId: sessionId,
      socket: socket,
      docId,
    });
  };

  const placeImageObject = ({
    selectedPage,
    payload,
    typeOfStamp,
    scale,
  }: {
    typeOfStamp?: string;
  } & ParamsType) => {
    const id = uuidv4();
    if (payload.type === EventType.notarySignature) {
      addAuditLog({
        selectedPage,
        payload: {
          ...payload,
          id,
          url: notarySignatureUrl,
          x: payload.x ? payload.x : 0,
          y: payload.y ? payload.y : 0,
          width: payload.width ? payload.width : 100,
          height: payload.height ? payload.height : 60,
          ratio: payload.ratio || 1,
          zoomScale: scale,
        },
        docId,
      });
      emitEvent({
        action: EventAction.add,
        event: EventType.notarySignature,
        pageNumber: selectedPage,
        payload: {
          ...payload,
          id,
          url: notarySignatureUrl,
          x: payload.x ? payload.x : 0,
          y: payload.y ? payload.y : 0,
          width: payload.width ? payload.width : 100,
          height: payload.height ? payload.height : 60,
          ratio: payload.ratio || 1,
          zoomScale: scale,
        },
        sessionId: sessionId,
        socket: socket,
        docId,
      });
    } else if (payload.type === EventType.notaryStamp) {
      addAuditLog({
        selectedPage,
        payload: {
          ...payload,
          id,
          url: notaryStampUrl,
          x: payload.x ? payload.x : 0,
          y: payload.y ? payload.y : 0,
          width: payload.width ? payload.width : 130,
          height: payload.height ? payload.height : 70,
          ratio: payload.ratio || 1,
          zoomScale: scale,
        },
        docId,
      });
      emitEvent({
        action: EventAction.add,
        event: EventType.notaryStamp,
        pageNumber: selectedPage,
        payload: {
          ...payload,
          id,
          url: notaryStampUrl,
          typeOfStamp: typeOfStamp,
          x: payload.x ? payload.x : 0,
          y: payload.y ? payload.y : 0,
          width: payload.width ? payload.width : 130,
          height: payload.height ? payload.height : 0,
          ratio: payload.ratio || 1,
          zoomScale: scale,
        },
        sessionId: sessionId,
        socket: socket,
        docId,
      });
    } else if (payload.type === EventType.notaryInitials) {
      addAuditLog({
        selectedPage,
        payload: {
          ...payload,
          id,
          url: notaryInitialsUrl,
          x: payload.x ? payload.x : 0,
          y: payload.y ? payload.y : 0,
          width: payload.width ? payload.width : 100,
          height: payload.height ? payload.height : 60,
          ratio: payload.ratio || 1,
          zoomScale: scale,
        },
        docId,
      });
      emitEvent({
        action: EventAction.add,
        event: EventType.notaryInitials,
        pageNumber: selectedPage,
        payload: {
          ...payload,
          id,
          url: notaryInitialsUrl,
          x: payload.x ? payload.x : 0,
          y: payload.y ? payload.y : 0,
          width: payload.width ? payload.width : 100,
          height: payload.height ? payload.height : 60,
          ratio: payload.ratio || 1,
          zoomScale: scale,
        },
        sessionId: sessionId,
        socket: socket,
        docId,
      });
    } else {
      console.log("unHandled");
    }
  };

  return {
    placeTextObject,
    placeRectangleObject,
    placeImageObject,
    placeDateObject,
    placeCheckboxObject,
    placeDisclosureObject,
    getSignatureObject,
    placeScibbleObject,
    getLabelInput,
  };
};
export default useAuditActions;
