/* tslint:disable */
/* eslint-disable */
/**
 * Notarize Genie
 * Notarize Genie API Integration
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { MeetingMediator } from './MeetingMediator';
import {
    MeetingMediatorFromJSON,
    MeetingMediatorFromJSONTyped,
    MeetingMediatorToJSON,
} from './MeetingMediator';
import type { Signer } from './Signer';
import {
    SignerFromJSON,
    SignerFromJSONTyped,
    SignerToJSON,
} from './Signer';
import type { Witness } from './Witness';
import {
    WitnessFromJSON,
    WitnessFromJSONTyped,
    WitnessToJSON,
} from './Witness';
import type { KbaType } from './KbaType';
import {
    KbaTypeFromJSON,
    KbaTypeFromJSONTyped,
    KbaTypeToJSON,
} from './KbaType';
import type { Notary } from './Notary';
import {
    NotaryFromJSON,
    NotaryFromJSONTyped,
    NotaryToJSON,
} from './Notary';
import type { OmitTypeClass } from './OmitTypeClass';
import {
    OmitTypeClassFromJSON,
    OmitTypeClassFromJSONTyped,
    OmitTypeClassToJSON,
} from './OmitTypeClass';
import type { Document } from './Document';
import {
    DocumentFromJSON,
    DocumentFromJSONTyped,
    DocumentToJSON,
} from './Document';

/**
 * 
 * @export
 * @interface Session
 */
export interface Session {
    /**
     * 
     * @type {number}
     * @memberof Session
     */
    sessionId: number;
    /**
     * 
     * @type {number}
     * @memberof Session
     */
    witnessesCount?: number;
    /**
     * 
     * @type {string}
     * @memberof Session
     */
    scheduleDate?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Session
     */
    isScheduleEmailSent?: boolean;
    /**
     * 
     * @type {OmitTypeClass}
     * @memberof Session
     */
    user: OmitTypeClass;
    /**
     * 
     * @type {Notary}
     * @memberof Session
     */
    notary?: Notary;
    /**
     * 
     * @type {Array<MeetingMediator>}
     * @memberof Session
     */
    mediators?: Array<MeetingMediator>;
    /**
     * 
     * @type {Array<Signer>}
     * @memberof Session
     */
    signers: Array<Signer>;
    /**
     * 
     * @type {Array<Document>}
     * @memberof Session
     */
    documents?: Array<Document>;
    /**
     * 
     * @type {Array<Witness>}
     * @memberof Session
     */
    witness?: Array<Witness>;
    /**
     * 
     * @type {string}
     * @memberof Session
     */
    status: string;
    /**
     * 
     * @type {string}
     * @memberof Session
     */
    terminateReason: string;
    /**
     * 
     * @type {string}
     * @memberof Session
     */
    notaryNote?: string;
    /**
     * 
     * @type {string}
     * @memberof Session
     */
    paymentBy?: string;
    /**
     * 
     * @type {number}
     * @memberof Session
     */
    documentFees?: number;
    /**
     * 
     * @type {Array<KbaType>}
     * @memberof Session
     */
    kbaLogs?: Array<KbaType>;
    /**
     * 
     * @type {Date}
     * @memberof Session
     */
    createdDate: Date;
    /**
     * 
     * @type {boolean}
     * @memberof Session
     */
    isInvoicePaid: boolean;
    /**
     * 
     * @type {number}
     * @memberof Session
     */
    primarySignerUserId?: number;
    /**
     * 
     * @type {string}
     * @memberof Session
     */
    primarySignerName?: string;
    /**
     * 
     * @type {string}
     * @memberof Session
     */
    specificNotaryState?: string;
    /**
     * 
     * @type {string}
     * @memberof Session
     */
    invoiceNumber?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Session
     */
    isInhouseNotary?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Session
     */
    isDemoSession?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Session
     */
    isTitleLenderSession?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Session
     */
    isNotaryExemplarSheet?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Session
     */
    isESignSession?: boolean;
    /**
     * 
     * @type {Date}
     * @memberof Session
     */
    updatedDate: Date;
}

/**
 * Check if a given object implements the Session interface.
 */
export function instanceOfSession(value: object): value is Session {
    if (!('sessionId' in value) || value['sessionId'] === undefined) return false;
    if (!('user' in value) || value['user'] === undefined) return false;
    if (!('signers' in value) || value['signers'] === undefined) return false;
    if (!('status' in value) || value['status'] === undefined) return false;
    if (!('terminateReason' in value) || value['terminateReason'] === undefined) return false;
    if (!('createdDate' in value) || value['createdDate'] === undefined) return false;
    if (!('isInvoicePaid' in value) || value['isInvoicePaid'] === undefined) return false;
    if (!('updatedDate' in value) || value['updatedDate'] === undefined) return false;
    return true;
}

export function SessionFromJSON(json: any): Session {
    return SessionFromJSONTyped(json, false);
}

export function SessionFromJSONTyped(json: any, ignoreDiscriminator: boolean): Session {
    if (json == null) {
        return json;
    }
    return {
        
        'sessionId': json['sessionId'],
        'witnessesCount': json['witnessesCount'] == null ? undefined : json['witnessesCount'],
        'scheduleDate': json['scheduleDate'] == null ? undefined : json['scheduleDate'],
        'isScheduleEmailSent': json['isScheduleEmailSent'] == null ? undefined : json['isScheduleEmailSent'],
        'user': OmitTypeClassFromJSON(json['user']),
        'notary': json['notary'] == null ? undefined : NotaryFromJSON(json['notary']),
        'mediators': json['mediators'] == null ? undefined : ((json['mediators'] as Array<any>).map(MeetingMediatorFromJSON)),
        'signers': ((json['signers'] as Array<any>).map(SignerFromJSON)),
        'documents': json['documents'] == null ? undefined : ((json['documents'] as Array<any>).map(DocumentFromJSON)),
        'witness': json['witness'] == null ? undefined : ((json['witness'] as Array<any>).map(WitnessFromJSON)),
        'status': json['status'],
        'terminateReason': json['terminateReason'],
        'notaryNote': json['notaryNote'] == null ? undefined : json['notaryNote'],
        'paymentBy': json['paymentBy'] == null ? undefined : json['paymentBy'],
        'documentFees': json['documentFees'] == null ? undefined : json['documentFees'],
        'kbaLogs': json['kbaLogs'] == null ? undefined : ((json['kbaLogs'] as Array<any>).map(KbaTypeFromJSON)),
        'createdDate': (new Date(json['createdDate'])),
        'isInvoicePaid': json['isInvoicePaid'],
        'primarySignerUserId': json['primarySignerUserId'] == null ? undefined : json['primarySignerUserId'],
        'primarySignerName': json['primarySignerName'] == null ? undefined : json['primarySignerName'],
        'specificNotaryState': json['specificNotaryState'] == null ? undefined : json['specificNotaryState'],
        'invoiceNumber': json['invoiceNumber'] == null ? undefined : json['invoiceNumber'],
        'isInhouseNotary': json['isInhouseNotary'] == null ? undefined : json['isInhouseNotary'],
        'isDemoSession': json['isDemoSession'] == null ? undefined : json['isDemoSession'],
        'isTitleLenderSession': json['isTitleLenderSession'] == null ? undefined : json['isTitleLenderSession'],
        'isNotaryExemplarSheet': json['isNotaryExemplarSheet'] == null ? undefined : json['isNotaryExemplarSheet'],
        'isESignSession': json['isESignSession'] == null ? undefined : json['isESignSession'],
        'updatedDate': (new Date(json['updatedDate'])),
    };
}

export function SessionToJSON(value?: Session | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'sessionId': value['sessionId'],
        'witnessesCount': value['witnessesCount'],
        'scheduleDate': value['scheduleDate'],
        'isScheduleEmailSent': value['isScheduleEmailSent'],
        'user': OmitTypeClassToJSON(value['user']),
        'notary': NotaryToJSON(value['notary']),
        'mediators': value['mediators'] == null ? undefined : ((value['mediators'] as Array<any>).map(MeetingMediatorToJSON)),
        'signers': ((value['signers'] as Array<any>).map(SignerToJSON)),
        'documents': value['documents'] == null ? undefined : ((value['documents'] as Array<any>).map(DocumentToJSON)),
        'witness': value['witness'] == null ? undefined : ((value['witness'] as Array<any>).map(WitnessToJSON)),
        'status': value['status'],
        'terminateReason': value['terminateReason'],
        'notaryNote': value['notaryNote'],
        'paymentBy': value['paymentBy'],
        'documentFees': value['documentFees'],
        'kbaLogs': value['kbaLogs'] == null ? undefined : ((value['kbaLogs'] as Array<any>).map(KbaTypeToJSON)),
        'createdDate': ((value['createdDate']).toISOString()),
        'isInvoicePaid': value['isInvoicePaid'],
        'primarySignerUserId': value['primarySignerUserId'],
        'primarySignerName': value['primarySignerName'],
        'specificNotaryState': value['specificNotaryState'],
        'invoiceNumber': value['invoiceNumber'],
        'isInhouseNotary': value['isInhouseNotary'],
        'isDemoSession': value['isDemoSession'],
        'isTitleLenderSession': value['isTitleLenderSession'],
        'isNotaryExemplarSheet': value['isNotaryExemplarSheet'],
        'isESignSession': value['isESignSession'],
        'updatedDate': ((value['updatedDate']).toISOString()),
    };
}

