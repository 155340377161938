import {
  Heading,
  RadioGroup,
  Stack,
  Radio,
  Textarea,
  Text,
  Button,
  Flex,
} from "@chakra-ui/react";
import { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSessionCreate } from "../../../store/store";
import { notaryPaths } from "../../../routes/routes";
import useCreateUser from "../../../hooks/useCreateUser";
import useNotification from "../../../utils/useNotification";
import { ESignSessionV2Api, NotaryApi, SignerApi } from "../../../api";
import { useAuth } from "../../../utils/use-auth";

const NotaryNotes = () => {
  const navigate = useNavigate();
  const { sessionDetail, setSessionDetails } = useSessionCreate();
  const [hasNote, setHasNote] = useState<boolean>(false);
  const sendSynchronously = useCreateUser();
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const { notification } = useNotification();
  const { configuration } = useAuth();
  const UserID = localStorage.getItem("UserID");

  const handleRadioButton = (value: string) => {
    if (value === "yes") {
      setHasNote(true);
      setSessionDetails({
        ...sessionDetail,
        notaryNote: "",
      });
    } else if (value === "no") {
      setHasNote(false);
      setSessionDetails({
        ...sessionDetail,
        notaryNote: "",
      });
    }
  };

  useEffect(() => {
    if (sessionDetail.notaryNote !== "") {
      setHasNote(true);
    } else {
      setHasNote(false);
    }
  }, [sessionDetail.notaryNote]);

  const isEsignSession = useMemo(
    () => sessionDetail?.isEsign,
    [sessionDetail?.isEsign]
  );

  const handleNext = async () => {
    if (isEsignSession) {
      try {
        setIsSubmitting(true);
        const users = await sendSynchronously();
        const signersPromise = sessionDetail?.signers.map((signer)=>
        new SignerApi(configuration).signerControllerCreate({
          signerDTO:{
            firstName:signer?.firstName,
            lastName: signer?.lastName,
            email: signer?.email?.toLowerCase(),
            phoneNumber: signer?.phoneNumber?.replace(/[^\d]/g, ""),
          }
        })
        )
        const signers = await Promise.all(signersPromise);
        const signerIds = signers.map((s) => s.signerId?.toString());
        const notaryResponse = await new NotaryApi(
          configuration
        ).notaryControllerFindOne({
          userId: UserID ?? "",
        });
        const notaryId = notaryResponse.notaryId;

        // #NOTE : create E-sign session here with users and notary, then navigate to prepare document page to preTag document.
        const eSessionResponse = await new ESignSessionV2Api(
          configuration
        ).eSignSessionControllerCreate({
          eSignSessionDTO: {
            primarySignerName: signers[0]?.firstName + " " + signers[0]?.lastName,
            primarySignerUserId: signers[0]?.user?.id,
            signers: signerIds,
            isESignSession: true,
            notaryNote: sessionDetail?.notaryNote,
            notary: notaryId,
            documents: sessionDetail.documents.map((doc) =>
              doc.docId?.toString()
            ),
          },
        });
        console.log("eSessionResponse", eSessionResponse);
        notification("success", "Session Created Successfully");
        navigate(`${notaryPaths.prepareSession}/${eSessionResponse.sessionId}`);
        // #TODO : navigate to prepare document page
      } catch (error) {
        setIsSubmitting(false);
        notification("fail", "Failed to Create Session");
      } finally {
        setIsSubmitting(false);
      }
    } else {
      navigate(`${notaryPaths.createSession}/payment`);
    }
  };

  return (
    <>
      <Heading
        fontSize={{ base: "18px", md: "22px", lg: "22px" }}
        lineHeight="30px"
        textAlign={"center"}
        mb={6}
        fontFamily="Oswald"
        fontWeight={600}
      >
        Notary Notes
      </Heading>
      <Text fontSize={20} style={{ fontWeight: "600", marginBottom: "10px" }}>
        Do you want to include any notes for yourself ?
      </Text>
      <Text mb={"5px"}>
        You can leave any note here if the signing requires any special
        instructions and you will be able to see it before beginning the signing
        process.
      </Text>
      <RadioGroup
        value={hasNote ? "yes" : "no"}
        onChange={(value) => handleRadioButton(value)}
      >
        <Stack direction="row" gap={3} mt={"10px"} mb={3}>
          <Radio value="no">No</Radio>
          <Radio value="yes">Yes</Radio>
        </Stack>
      </RadioGroup>

      {hasNote && (
        <Textarea
          value={sessionDetail.notaryNote}
          placeholder="Enter your note here..."
          onChange={(e) => {
            setSessionDetails({ ...sessionDetail, notaryNote: e.target.value });
          }}
        />
      )}

      <Flex justifyContent={"flex-end"} mt={"20px"}>
        <Button
          marginTop={5}
          width={"fit-content"}
          style={{ padding: "10px 30px" }}
          onClick={() => {
            navigate(`${notaryPaths.createSession}/document-upload`);
          }}
        >
          Prev
        </Button>
        {isEsignSession ? (
          <Button
            marginTop={5}
            ml={2}
            style={{
              background: "#2D3748",
              color: "#fff",
              padding: "10px 30px",
            }}
            width={"fit-content"}
            onClick={() => {
              handleNext();
            }}
            isDisabled={
              (hasNote && sessionDetail.notaryNote === "") || isSubmitting
            }
          >
            Submit
          </Button>
        ) : (
          <Button
            marginTop={5}
            ml={2}
            style={{
              background: "#2D3748",
              color: "#fff",
              padding: "10px 30px",
            }}
            width={"fit-content"}
            onClick={() => {
              navigate(`${notaryPaths.createSession}/payment`);
            }}
            isDisabled={hasNote && sessionDetail.notaryNote === ""}
          >
            Next
          </Button>
        )}
      </Flex>
    </>
  );
};

export default NotaryNotes;
