import {
  Box,
  Button,
  Center,
  Flex,
  Input,
  Menu,
  MenuButton,
  MenuList,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Spinner,
  Text,
  Tooltip,
  useDisclosure,
} from "@chakra-ui/react";
import { useAuditLogsStore } from "../../../store/auditLogs";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { FaRegCircle } from "react-icons/fa";
import { CheckCircleIcon } from "@chakra-ui/icons";
import { Link, useNavigate } from "react-router-dom";
import useNotification from "../../../utils/useNotification";
import { useActiveSessionStore } from "../../../store/activeSessionStore";
import { ESignSessionV2Api } from "../../../api";
import { useAuth } from "../../../utils/use-auth";

const taskNames = {
  signerSignature: "Signer Signature",
  witnessSignature: "Witness Signature",
  signerInitials: "Signer Initials",
  labelInput: "Tagged Input",
};

function TasksButton({
  isFinishing,
  onFinish,
}: {
  isFinishing: boolean;
  onFinish: () => void;
}) {
  const role = localStorage?.getItem("role");
  const optOutInputRef = useRef<HTMLInputElement>(null);
  const { configuration } = useAuth();
  const navigate = useNavigate();

  const [tasks, setTasks] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { onOpen, onClose, isOpen } = useDisclosure();
  const [othersTasks, setOthersTasks] = useState([]);
  const auditLogs = useAuditLogsStore((state) => state.auditLogs);
  const { notification } = useNotification();
  const UserID = localStorage?.getItem("UserID");
  const sessionInfo = useActiveSessionStore((state) => state.sessionInfo);
  const primarySignerUserId = useMemo(
    () => sessionInfo?.primarySignerUserId,
    [sessionInfo?.primarySignerUserId]
  );

  const optOutFromDocument = useCallback(() => {
    if (optOutInputRef.current) {
      if (!optOutInputRef.current.value) {
        notification("fail", "Please enter a reason");
        return;
      } else {
        setIsSubmitting(true);
        new ESignSessionV2Api(configuration)
          .eSignSessionControllerOptOut({
            optOutDto: {
              reason: optOutInputRef.current.value,
            },
            sessionId: Number(sessionInfo?.sessionId),
          })
          .then((res) => {
            notification("success", "Opted out successfully");
            optOutInputRef.current.value = "";
            navigate("/signer/esign-sessions");
          })
          .catch((err) => {
            console.log(err);
            notification("fail", "Failed to opt out");
          }).finally(() => {
            setIsSubmitting(false);
          })
      }
    }
  }, [sessionInfo?.sessionId]);

  useEffect(() => {
    // #NOTE: any signature related task is said to be pending, if it does not have url field
    // and any labelInput is said to be pending, if the text field is empty

    const myTasks = auditLogs
      ?.map((el, index) =>
        el
          ?.filter((el) => Number(el?.toBeSignedByUserId) === Number(UserID))
          ?.map((el) => ({
            ...el,
            pageNumber: index + 1,
            pending: isPending(el),
          }))
      )
      ?.flat();

    const othersTasks = auditLogs
      ?.map((el, index) =>
        el
          ?.filter(
            (el) =>
              el?.toBeSignedByUserId &&
              Number(el?.toBeSignedByUserId) !== Number(UserID)
          )
          ?.map((el) => ({
            ...el,
            pageNumber: index + 1,
            pending: isPending(el),
          }))
      )
      ?.flat();

    setOthersTasks(othersTasks);
    setTasks(myTasks);
  }, [auditLogs, UserID]);

  const completed = useMemo(
    () => tasks?.filter((task) => !task.pending),
    [tasks]
  )?.length;

  function isPending(task) {
    if (
      ["signerSignature", "witnessSignature", "signerInitials"].includes(
        task?.type
      ) &&
      !task?.url
    )
      return true;
    if (task?.type === "labelInput" && !task?.text) return true;
    return false;
  }

  const allTasks = useMemo(
    () =>
      auditLogs
        ?.map((el, index) =>
          el
            ?.filter((el) => el?.toBeSignedByUserId)
            ?.map((el) => ({
              ...el,
              pageNumber: index + 1,
              pending: isPending(el),
            }))
        )
        ?.flat(),
    [auditLogs]
  );

  const isAllTaskCompleted = useMemo(
    () => allTasks?.every((el) => !el?.pending),
    [allTasks]
  );

  if (!UserID) return null;

  if (!auditLogs) return null;
  if (auditLogs.length === 0) return null;

  if (isFinishing)
    return (
      <Center>
        <Spinner size={"sm"} thickness="2px" />
      </Center>
    );
  return (
    <Flex gap={2}>
      <Tooltip label="if there is something missnig, please opt out. this will notify the creator of this session">
        <Box>
          <Popover
            offset={[-80, 18]}
            closeOnBlur
            isOpen={isOpen}
            onOpen={() => {
              onOpen();
            }}
            onClose={onClose}
          >
            <PopoverTrigger>
              <Button>Opt out </Button>
            </PopoverTrigger>
            <PopoverContent color="#000" px={3} py={2}>
              <PopoverArrow />
              <PopoverCloseButton />
              <PopoverBody pt={5}>
                <Input ref={optOutInputRef} type="text" placeholder="Reason" />
                <Flex justifyContent={"flex-end"} mt={"20px"}>
                  <Button
                    w={"70px"}
                    p="4"
                    background="white"
                    border={"1px solid rgba(0, 0, 128, 1)"}
                    borderRadius="10px"
                    fontSize={"16px"}
                    color="rgba(0, 0, 128, 1)"
                    onClick={() => onClose()}
                    mr={3}
                  >
                    Cancel
                  </Button>
                  <Button
                    // w={"70px"}
                    p="4"
                    background="linear-gradient(180deg, #D5AD36 0%, rgba(213, 173, 54, 0.5) 100%)"
                    borderRadius="10px"
                    fontSize={"16px"}
                    _hover={{
                      background:
                        "linear-gradient(180deg, #D5AD36 0%, rgba(213, 173, 54, 0.5) 100%)",
                    }}
                    onClick={() => {
                      optOutFromDocument();
                    }}
                  >
                    {isSubmitting ? "Sending...": "Send"}
                  </Button>
                </Flex>
              </PopoverBody>
            </PopoverContent>
          </Popover>
        </Box>
      </Tooltip>
      {tasks?.length > 0 && (
        <Menu>
          <MenuButton as={Button}>
            Tasks {completed}/{tasks?.length}
          </MenuButton>
          <MenuList>
            <Box padding={4}>
              <Text
                style={{
                  fontWeight: 700,
                  fontSize: "18px",
                }}
              >
                Session Tasks {completed}/{tasks?.length}
              </Text>
              <Text
                style={{
                  fontSize: "14px",
                  color: "#0D0B2C",
                  fontWeight: 400,
                }}
              >
                My Tasks
              </Text>
              {/* list of tasks */}
              {tasks?.map((task) => (
                <Flex
                  gap={2}
                  alignItems={"center"}
                  key={task.id}
                  mt={2}
                  cursor={"pointer"}
                  onClick={() => {
                    console.log(task);

                    const section = document.querySelector(
                      `#page${task?.pageNumber - 1}`
                    );
                    section?.scrollIntoView({
                      behavior: "smooth",
                      block: "start",
                    });
                  }}
                >
                  {task.pending ? <FaRegCircle /> : <CheckCircleIcon />}
                  <Text
                    style={{
                      textDecoration: task.pending ? "none" : "line-through",
                    }}
                    casing={"capitalize"}
                  >
                    {" "}
                    {`${taskNames[task?.type]} - Page ${task?.pageNumber}`}
                  </Text>
                </Flex>
              ))}
              {othersTasks.length > 0 && (
                <>
                  <Text
                    style={{
                      fontSize: "14px",
                      color: "#0D0B2C",
                      fontWeight: 400,
                    }}
                  >
                    Others Tasks
                  </Text>
                  {othersTasks?.map((task) => (
                    <Flex
                      gap={2}
                      alignItems={"center"}
                      key={task.id}
                      mt={2}
                      cursor={"pointer"}
                      onClick={() => {
                        console.log(task);

                        const section = document.querySelector(
                          `#page${task?.pageNumber - 1}`
                        );
                        section?.scrollIntoView({
                          behavior: "smooth",
                          block: "start",
                        });
                      }}
                    >
                      {task.pending ? <FaRegCircle /> : <CheckCircleIcon />}
                      <Text
                        style={{
                          textDecoration: task.pending
                            ? "none"
                            : "line-through",
                        }}
                        casing={"capitalize"}
                      >
                        {" "}
                        {`${taskNames[task?.type]} - Page ${task?.pageNumber}`}
                      </Text>
                    </Flex>
                  ))}
                </>
              )}
            </Box>
          </MenuList>
        </Menu>
      )}
      {Number(primarySignerUserId) === Number(UserID) ? (
        <Button
          style={{
            background:
              "linear-gradient(180deg, #D5AD36 0%, rgba(213, 173, 54, 0.5) 100%)",
            color: "#000",
            padding: "10px 20px",
          }}
          onClick={() => {
            if (completed < tasks?.length) {
              // alert("Please complete all tasks")
              notification("fail", "Please complete all of your tasks");
              return;
            }
            if (!isAllTaskCompleted) {
              notification("fail", "All Signers must finish their tasks");
              return;
            }
            onFinish && onFinish();
          }}
        >
          Finish
        </Button>
      ) : role === "notary" ?
      (
        <Link to={`/notary/sessions/active`}>
          <Button
            style={{
              background:
                "linear-gradient(180deg, #D5AD36 0%, rgba(213, 173, 54, 0.5) 100%)",
              color: "#000",
              padding: "10px 20px",
            }}
          >
            Save
          </Button>
        </Link>
      ):
      (
        <Link to={`/signer/list`}>
          <Button
            style={{
              background:
                "linear-gradient(180deg, #D5AD36 0%, rgba(213, 173, 54, 0.5) 100%)",
              color: "#000",
              padding: "10px 20px",
            }}
          >
            Save
          </Button>
        </Link>
      )}
    </Flex>
  );
}

export default TasksButton;
