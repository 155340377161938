//@ts-nocheck
import {
  Button,
  Divider,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Radio,
  RadioGroup,
  Stack,
  Text,
} from "@chakra-ui/react";
import Select from "react-select";
import MaskedInput from "react-text-mask";
import { ChangeEvent, FocusEvent, useMemo, useState } from "react";
import { ISigner } from "../../../../store/zustandType";
import { ErrorMessage, Field, FieldArray } from "formik";
import { phoneNumberMask, validateBirthday } from "../../../../utils/utils";
import { StateFullnameOptions } from "../../../../utils/options";
import { MdDeleteOutline } from "react-icons/md";

const SignersDetail = ({
  values,
  from,
  setFieldValue,
  handleChange,
  handleBlur,
  isEsignSession=false
}: {
  from: string;
  values: {
    signers: ISigner[];
  };
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => void;
  handleChange: (e: ChangeEvent<any>) => void;
  handleBlur: (e: FocusEvent<any, Element>) => void;
  isEsignSession?: boolean;
}) => {
  const [isValidDOB, setIsValidDOB] = useState<number>(-1);

  return (
    <FieldArray
      name="signers"
      render={(arrayHelpers) => {
        const signers = values.signers;
        return (
          <div>
            {signers && signers.length > 0
              ? signers.map((signer, index) => (
                  <div key={index}>
                    <Flex justifyContent="space-between">
                      {index !== 0 && (
                        <Text
                          fontWeight={600}
                          fontSize={"20px"}
                          color={"#000080"}
                          pb={"15px"}
                          mb={"20px"}
                        >
                          Additional Signer {index}
                        </Text>
                      )}
                      {index !== 0 && (
                        <Button
                          color={"#df2c14"}
                          height={"40px"}
                          _hover={{
                            background: "#df2c14",
                            color: "#fff",
                          }}
                          border={"1px solid #df2c14"}
                          backgroundColor={"white"}
                          p={0}
                          width={"100px"}
                          onClick={() => arrayHelpers.remove(index)}
                        >
                          <MdDeleteOutline /> <Text ml={1}>Remove</Text>
                        </Button>
                      )}
                    </Flex>

                    <Flex flexWrap="wrap">
                      <FormControl
                        mb={6}
                        minW={"230px"}
                        w={["100%", "33%", "33%"]}
                        pr={4}
                      >
                        <FormLabel>First Name*</FormLabel>
                        <Field
                          className="field-input"
                          type="text"
                          fontSize="14px"
                          placeholder="John"
                          name={`signers.${index}.firstName`}
                          value={signer?.firstName}
                          style={{ textTransform: "capitalize" }}
                        />
                        <br />
                        <ErrorMessage
                          name={`signers.${index}.firstName`}
                          render={(msg) => (
                            <div className="field-input-error">{msg}</div>
                          )}
                        />
                      </FormControl>

                      <FormControl
                        minW={"230px"}
                        w={["100%", "33%", "33%"]}
                        pr={4}
                        mb={6}
                      >
                        <FormLabel>Last Name*</FormLabel>
                        <Field
                          className="field-input"
                          type="text"
                          placeholder="Doe"
                          name={`signers.${index}.lastName`}
                          value={signer?.lastName}
                          style={{ textTransform: "capitalize" }}
                        />
                        <br />
                        <ErrorMessage
                          name={`signers.${index}.lastName`}
                          render={(msg) => (
                            <div className="field-input-error">{msg}</div>
                          )}
                        />
                      </FormControl>

                      <FormControl
                        mb={6}
                        minW={"230px"}
                        w={["100%", "33%", "33%"]}
                        pr={4}
                      >
                        <FormLabel>Email*</FormLabel>
                        <Field
                          className="field-input"
                          type="email"
                          pattern="\b[\w\.-]+@[\w\.-]+\.\w{2,4}\b"
                          placeholder="johndoe123@email.com"
                          name={`signers.${index}.email`}
                          value={signer?.email}
                          disabled={from === "signer" ? index === 0 : false}
                        />
                        <br />
                        <ErrorMessage
                          name={`signers.${index}.email`}
                          render={(msg) => (
                            <div className="field-input-error">{msg}</div>
                          )}
                        />
                      </FormControl>

                      <FormControl
                        mb={6}
                        minW={"230px"}
                        w={["100%", "33%", "33%"]}
                        pr={4}
                      >
                        <FormLabel>Phone Number*</FormLabel>
                        <Field
                          name={`signers.${index}.phoneNumber`}
                          // @ts-ignore
                          render={({ field }) => (
                            <MaskedInput
                              {...field}
                              mask={phoneNumberMask}
                              id={`signers.${index}.phoneNumber`}
                              placeholder="(___)-___-____"
                              type="text"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              className="field-input"
                              value={signer?.phoneNumber}
                            />
                          )}
                        />
                        <br />
                        <ErrorMessage
                          name={`signers.${index}.phoneNumber`}
                          render={(msg) => (
                            <div className="field-input-error">{msg}</div>
                          )}
                        />
                      </FormControl>

                      {from === "signer" && (
                        <>
                          <FormControl
                            mb={6}
                            minW={"230px"}
                            w={["100%", "33%", "33%"]}
                            pr={4}
                          >
                            <FormLabel>DOB*</FormLabel>

                            <Field
                              className="field-input"
                              type="date"
                              placeholder=""
                              name={`signers.${index}.dob`}
                              value={signer?.dob}
                              date-format="mm/dd/yyyy"
                              onChange={(e: any) => {
                                setFieldValue(
                                  `signers.${index}.dob`,
                                  e.target.value
                                );
                                if (
                                  validateBirthday(e.target.value) === false
                                ) {
                                  setIsValidDOB(index);
                                } else {
                                  setIsValidDOB(-1);
                                }
                              }}
                            />

                            <br />
                            <ErrorMessage
                              name={`signers.${index}.dob`}
                              render={(msg) => (
                                <div className="field-input-error">{msg}</div>
                              )}
                            />
                            {isValidDOB === index && (
                              <Text
                                color="red"
                                fontSize={"14px"}
                                fontWeight="500"
                              >
                                Minimum age required is above 18
                              </Text>
                            )}
                          </FormControl>
                          <FormControl
                            minW={"230px"}
                            w={["100%", "33%", "33%"]}
                            pr={4}
                          >
                            <FormLabel>Last Four Digits of SSN*</FormLabel>
                            <Flex w={"100%"} justifyContent={"flex-start"}>
                              <Input
                                id="1"
                                value={"XXX"}
                                readOnly
                                disabled
                                maxW={"80px"}
                                mr={2}
                              />
                              <Input
                                id="2"
                                value={"XX"}
                                readOnly
                                disabled
                                maxW={"80px"}
                                mr={2}
                              />
                              <Field
                                maxLength={4}
                                minLength={4}
                                className="field-input"
                                type="text"
                                placeholder=""
                                name={`signers.${index}.lastFourDigitsSsnNumber`}
                                value={signer?.lastFourDigitsSsnNumber}
                              />
                            </Flex>

                            <ErrorMessage
                              name={`signers.${index}.lastFourDigitsSsnNumber`}
                              render={(msg) => (
                                <div className="field-input-error">{msg}</div>
                              )}
                            />
                          </FormControl>

                          <FormControl mb={6} minW={"280px"} w={"100%"} pr={4}>
                            <FormLabel>Street Address*</FormLabel>
                            <Field
                              className="field-input"
                              type="text"
                              placeholder="Street Address"
                              name={`signers.${index}.addressLine1`}
                              value={signer?.addressLine1}
                            />
                            <br />
                            <ErrorMessage
                              name={`signers.${index}.addressLine1`}
                              render={(msg) => (
                                <div className="field-input-error">{msg}</div>
                              )}
                            />
                          </FormControl>

                          <FormControl mb={6} minW={"280px"} w={"100%"} pr={4}>
                            <FormLabel>Address Line 2</FormLabel>
                            <Field
                              className="field-input"
                              type="text"
                              placeholder="Address Line 2"
                              name={`signers.${index}.addressLine2`}
                              value={signer?.addressLine2}
                            />
                            <br />
                            <ErrorMessage
                              name={`signers.${index}.addressLine2`}
                              render={(msg) => (
                                <div className="field-input-error">{msg}</div>
                              )}
                            />
                          </FormControl>

                          <FormControl
                            minW={"230px"}
                            w={["100%", "33%", "33%"]}
                            pr={4}
                          >
                            <FormLabel>City*</FormLabel>
                            <Field
                              className="field-input"
                              type="text"
                              placeholder="City"
                              name={`signers.${index}.city`}
                              value={signer?.city}
                              style={{ textTransform: "capitalize" }}
                            />
                            <br />
                            <ErrorMessage
                              name={`signers.${index}.city`}
                              render={(msg) => (
                                <div className="field-input-error">{msg}</div>
                              )}
                            />
                          </FormControl>

                          <FormControl
                            minW={"230px"}
                            w={["100%", "33%", "33%"]}
                            pr={4}
                          >
                            <FormLabel>State*</FormLabel>
                            <Field
                              name={`signers.${index}.state`}
                              // @ts-ignore
                              render={({ field }) => (
                                <Select
                                  {...field}
                                  id={`signers.${index}.state`}
                                  placeholder="Select State"
                                  options={StateFullnameOptions}
                                  menuPlacement="top"
                                  value={StateFullnameOptions.find(
                                    (item) => item.value === signer?.state
                                  )}
                                  onChange={(option) => {
                                    setFieldValue(
                                      `signers.${index}.state`,
                                      // @ts-ignore
                                      option?.value
                                    );
                                  }}
                                />
                              )}
                            />
                            <ErrorMessage
                              name={`signers.${index}.state`}
                              render={(msg) => (
                                <div className="field-input-error">{msg}</div>
                              )}
                            />
                          </FormControl>

                          <FormControl
                            minW={"230px"}
                            w={["100%", "33%", "33%"]}
                            pr={4}
                            mb={6}
                          >
                            <FormLabel>Zip Code*</FormLabel>
                            <Field
                              className="field-input"
                              type="text"
                              placeholder="Zip"
                              name={`signers.${index}.zip`}
                              value={signer?.zip}
                            />
                            <br />
                            <ErrorMessage
                              name={`signers.${index}.zip`}
                              render={(msg) => (
                                <div className="field-input-error">{msg}</div>
                              )}
                            />
                          </FormControl>
                        </>
                      )}

                      {/* {from !== "signer" && ( */}
                      {from === "notary" && isEsignSession===false && (
                        <FormControl minW={"280px"} w={"100%"} pr={4} mb={6}>
                          <FormLabel>
                            Do you personaly know the signer? If so, the signer
                            does not have to complete KBA and credentials
                            analysis.
                          </FormLabel>

                          <RadioGroup
                            name={`signers.${index}.kbaStatus`}
                            id={`signers.${index}.kbaStatus`}
                            value={
                              signer.kbaStatus === "pending" ? "no" : "yes"
                            }
                            onChange={(value) => {
                              if (value === "no") {
                                setFieldValue(
                                  `signers.${index}.kbaStatus`,
                                  "pending"
                                );
                              }
                              if (value === "yes") {
                                setFieldValue(
                                  `signers.${index}.kbaStatus`,
                                  "knownByNotary"
                                );
                              }
                            }}
                          >
                            <Stack
                              direction="row"
                              style={{ marginTop: "10PX" }}
                            >
                              <Radio value="no">No</Radio>
                              <Radio value="yes" pl={3}>
                                Yes
                              </Radio>
                            </Stack>
                          </RadioGroup>
                        </FormControl>
                      )}
                    </Flex>
                    <Divider my={4} />
                  </div>
                ))
              : null}

            <Button
              mt={3}
              width="fit-content"
              background="linear-gradient(180deg, #D5AD36 0%, rgba(213, 173, 54, 0.5) 100%)"
              _hover={{
                background:
                  "linear-gradient(180deg, #D5AD36 0%, rgba(213, 173, 54, 0.5) 100%)",
              }}
              disabled={arrayHelpers.form.values?.signers?.length >= 5}
              onClick={() => {
                if (from === "signer") {
                  arrayHelpers.push({
                    email: "",
                    firstName: "",
                    lastName: "",
                    phoneNumber: "",
                    addressLine1: "",
                    addressLine2: "",
                    city: "",
                    state: "",
                    zip: "",
                    dob: "",
                    lastFourDigitsSsnNumber: "",
                  });
                } else {
                  arrayHelpers.push({
                    email: "",
                    firstName: "",
                    lastName: "",
                    kbaStatus: "pending",
                  });
                }
              }}
            >
              + Add Signer
            </Button>
          </div>
        );
      }}
    />
  );
};

export default SignersDetail;
