import {
  Alert,
  AlertDescription,
  AlertIcon,
  Box,
  Radio,
  RadioGroup,
  Stack,
  Text,
} from "@chakra-ui/react";
import { useSessionCreate } from "../../../store/store";
import { useState } from "react";

const TitleLenderSession = () => {
  const { sessionDetail, setSessionDetails } = useSessionCreate();
  const [val,setVal] = useState(
    sessionDetail?.isEsign ? "e-sign" :
    sessionDetail?.isTitleLenderSession ? "title" : "general")

  return (
    <Box pt={5}>
      <Text fontWeight={600}>Which type of session you want to create?</Text>
      <RadioGroup
        // value={sessionDetail?.isTitleLenderSession ? "title" : "general"}
        value={val}
        onChange={(value) => {
          setVal(value)
          if (value === "title") {
            setSessionDetails({
              ...sessionDetail,
              isTitleLenderSession: true,
              isEsign: undefined,
            });
          } else if (value === "e-sign") {
            setSessionDetails({
              ...sessionDetail,
              isTitleLenderSession: false,
              isEsign: true,
            })
          } 
          else {
            setSessionDetails({
              ...sessionDetail,
              isTitleLenderSession: false,
              isEsign: undefined,
            });
          }
        }}
        mt={3}
      >
        <Stack direction="row" gap={3}>
          <Radio value="general">A General Notary Session</Radio>
          <Radio value="title">A Package Signing</Radio>
          <Radio value="e-sign">An E-Sign</Radio>
        </Stack>
      </RadioGroup>

      <Alert status="info" mb={4} mt={8}>
        <AlertIcon />
        <AlertDescription fontSize={"14px"} lineHeight={"5"}>
          <Text>
            <span style={{ fontWeight: "bold", textDecoration: "underline" }}>
              General Notary Session
            </span>{" "}
            - Notarize documents that are not related to real estate
            transactions. For example Power of Attorney, Trust documents,
            healthcare related documents, auto title transfers and other
            commonly notarized items. Sessions start at $24.99 for the first
            signer and stamp, each additional signer and stamp are $9.99 each.
          </Text>

          <Text mt={2}>
            <span style={{ fontWeight: "bold", textDecoration: "underline" }}>
              Package Signing
            </span>{" "}
            – Notarize Title/Lender documents that are related to real estate
            transactions. Sessions includes 10 stamps, 2 signers & 2 witnesses
            for $149.99
          </Text>
        </AlertDescription>
      </Alert>
    </Box>
  );
};

export default TitleLenderSession;
