/* tslint:disable */
/* eslint-disable */
/**
 * Notarize Genie
 * Notarize Genie API Integration
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  Business,
  BusinessDTO,
  DefaultResponse,
  IdologyAnswersDTO,
  IdologyDTO,
  IdologyDocDTO,
  KbaBody,
  RemoveNotary,
  SMSDTO,
  ScanUrl,
  Signer,
  SignerDTO,
  SignerUpdateDTO,
  UpdateBusinessDTO,
  UpdateInhouseNotaries,
  UpdateResponse,
  UpdatedDTO,
  VeriffRes,
  VeriffResPost,
  Witness,
  WitnessDTO,
} from '../models/index';
import {
    BusinessFromJSON,
    BusinessToJSON,
    BusinessDTOFromJSON,
    BusinessDTOToJSON,
    DefaultResponseFromJSON,
    DefaultResponseToJSON,
    IdologyAnswersDTOFromJSON,
    IdologyAnswersDTOToJSON,
    IdologyDTOFromJSON,
    IdologyDTOToJSON,
    IdologyDocDTOFromJSON,
    IdologyDocDTOToJSON,
    KbaBodyFromJSON,
    KbaBodyToJSON,
    RemoveNotaryFromJSON,
    RemoveNotaryToJSON,
    SMSDTOFromJSON,
    SMSDTOToJSON,
    ScanUrlFromJSON,
    ScanUrlToJSON,
    SignerFromJSON,
    SignerToJSON,
    SignerDTOFromJSON,
    SignerDTOToJSON,
    SignerUpdateDTOFromJSON,
    SignerUpdateDTOToJSON,
    UpdateBusinessDTOFromJSON,
    UpdateBusinessDTOToJSON,
    UpdateInhouseNotariesFromJSON,
    UpdateInhouseNotariesToJSON,
    UpdateResponseFromJSON,
    UpdateResponseToJSON,
    UpdatedDTOFromJSON,
    UpdatedDTOToJSON,
    VeriffResFromJSON,
    VeriffResToJSON,
    VeriffResPostFromJSON,
    VeriffResPostToJSON,
    WitnessFromJSON,
    WitnessToJSON,
    WitnessDTOFromJSON,
    WitnessDTOToJSON,
} from '../models/index';

export interface SignerControllerAnswersIdologyRequest {
    idologyAnswersDTO: IdologyAnswersDTO;
}

export interface SignerControllerCreateRequest {
    signerDTO: SignerDTO;
}

export interface SignerControllerCreateBusinessRequest {
    businessDTO: BusinessDTO;
}

export interface SignerControllerCreateSignerRequest {
    userId: number;
    signerDTO: SignerDTO;
}

export interface SignerControllerCreateWitnessRequest {
    witnessDTO: WitnessDTO;
}

export interface SignerControllerGetBusinessRequest {
    userId: number;
}

export interface SignerControllerGetProfileUserRequest {
    userId: number;
}

export interface SignerControllerGetWitnessRequest {
    userId: number;
}

export interface SignerControllerPartialUpdateBusinessRequest {
    id: number;
    updateBusinessDTO: UpdateBusinessDTO;
}

export interface SignerControllerPartialUpdateInhouseNotaryRequest {
    businessId: number;
    updateInhouseNotaries: UpdateInhouseNotaries;
}

export interface SignerControllerPartialUpdateSignerRequest {
    signerUpdateDTO: SignerUpdateDTO;
}

export interface SignerControllerPartialUpdateSignerParamRequest {
    userId: number;
    signerUpdateDTO: SignerUpdateDTO;
}

export interface SignerControllerQuestionsIdologyRequest {
    lastSsn4: string;
    dob: string;
}

export interface SignerControllerReScanRequest {
    kbaBody: KbaBody;
}

export interface SignerControllerRemoveInhouseNotaryRequest {
    businessId: number;
    removeNotary: RemoveNotary;
}

export interface SignerControllerScanResponseRequest {
    idologyIdNumber: number;
}

export interface SignerControllerSendSmsRequest {
    sMSDTO: SMSDTO;
}

export interface SignerControllerUpdateBusinessRequest {
    id: number;
    updateBusinessDTO: UpdateBusinessDTO;
}

export interface SignerControllerUpdateInhouseNotaryRequest {
    businessId: number;
    updateInhouseNotaries: UpdateInhouseNotaries;
}

export interface SignerControllerUpdateSignerRequest {
    signerUpdateDTO: SignerUpdateDTO;
}

export interface SignerControllerUpdateSignerParamRequest {
    userId: number;
    signerUpdateDTO: SignerUpdateDTO;
}

export interface SignerControllerVeriffSessionRequest {
    lastSsn4: string;
}

export interface SignerControllerVeriffSessionAttemptsRequest {
    kbalogId: number;
}

/**
 * 
 */
export class SignerApi extends runtime.BaseAPI {

    /**
     */
    async signerControllerAnswersIdologyRaw(requestParameters: SignerControllerAnswersIdologyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<IdologyDTO>> {
        if (requestParameters['idologyAnswersDTO'] == null) {
            throw new runtime.RequiredError(
                'idologyAnswersDTO',
                'Required parameter "idologyAnswersDTO" was null or undefined when calling signerControllerAnswersIdology().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/signer/idology/answers`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: IdologyAnswersDTOToJSON(requestParameters['idologyAnswersDTO']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IdologyDTOFromJSON(jsonValue));
    }

    /**
     */
    async signerControllerAnswersIdology(requestParameters: SignerControllerAnswersIdologyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<IdologyDTO> {
        const response = await this.signerControllerAnswersIdologyRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async signerControllerCreateRaw(requestParameters: SignerControllerCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Signer>> {
        if (requestParameters['signerDTO'] == null) {
            throw new runtime.RequiredError(
                'signerDTO',
                'Required parameter "signerDTO" was null or undefined when calling signerControllerCreate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/signer/profile`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SignerDTOToJSON(requestParameters['signerDTO']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SignerFromJSON(jsonValue));
    }

    /**
     */
    async signerControllerCreate(requestParameters: SignerControllerCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Signer> {
        const response = await this.signerControllerCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async signerControllerCreateBusinessRaw(requestParameters: SignerControllerCreateBusinessRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Business>> {
        if (requestParameters['businessDTO'] == null) {
            throw new runtime.RequiredError(
                'businessDTO',
                'Required parameter "businessDTO" was null or undefined when calling signerControllerCreateBusiness().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/signer/business/profile`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: BusinessDTOToJSON(requestParameters['businessDTO']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BusinessFromJSON(jsonValue));
    }

    /**
     */
    async signerControllerCreateBusiness(requestParameters: SignerControllerCreateBusinessRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Business> {
        const response = await this.signerControllerCreateBusinessRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async signerControllerCreateSignerRaw(requestParameters: SignerControllerCreateSignerRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Signer>> {
        if (requestParameters['userId'] == null) {
            throw new runtime.RequiredError(
                'userId',
                'Required parameter "userId" was null or undefined when calling signerControllerCreateSigner().'
            );
        }

        if (requestParameters['signerDTO'] == null) {
            throw new runtime.RequiredError(
                'signerDTO',
                'Required parameter "signerDTO" was null or undefined when calling signerControllerCreateSigner().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/signer/profile/{userId}`.replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters['userId']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SignerDTOToJSON(requestParameters['signerDTO']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SignerFromJSON(jsonValue));
    }

    /**
     */
    async signerControllerCreateSigner(requestParameters: SignerControllerCreateSignerRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Signer> {
        const response = await this.signerControllerCreateSignerRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async signerControllerCreateWitnessRaw(requestParameters: SignerControllerCreateWitnessRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Witness>> {
        if (requestParameters['witnessDTO'] == null) {
            throw new runtime.RequiredError(
                'witnessDTO',
                'Required parameter "witnessDTO" was null or undefined when calling signerControllerCreateWitness().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/signer/add/witness`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: WitnessDTOToJSON(requestParameters['witnessDTO']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => WitnessFromJSON(jsonValue));
    }

    /**
     */
    async signerControllerCreateWitness(requestParameters: SignerControllerCreateWitnessRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Witness> {
        const response = await this.signerControllerCreateWitnessRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async signerControllerDocumentRetriveRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<IdologyDocDTO>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/signer/idology/get-document`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IdologyDocDTOFromJSON(jsonValue));
    }

    /**
     */
    async signerControllerDocumentRetrive(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<IdologyDocDTO> {
        const response = await this.signerControllerDocumentRetriveRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async signerControllerGetBusinessRaw(requestParameters: SignerControllerGetBusinessRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Business>> {
        if (requestParameters['userId'] == null) {
            throw new runtime.RequiredError(
                'userId',
                'Required parameter "userId" was null or undefined when calling signerControllerGetBusiness().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/signer/business/{userId}`.replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters['userId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BusinessFromJSON(jsonValue));
    }

    /**
     */
    async signerControllerGetBusiness(requestParameters: SignerControllerGetBusinessRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Business> {
        const response = await this.signerControllerGetBusinessRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async signerControllerGetKbaLogsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/signer/kba-logs`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async signerControllerGetKbaLogs(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.signerControllerGetKbaLogsRaw(initOverrides);
    }

    /**
     */
    async signerControllerGetProfileRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Signer>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/signer/profile`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SignerFromJSON(jsonValue));
    }

    /**
     */
    async signerControllerGetProfile(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Signer> {
        const response = await this.signerControllerGetProfileRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async signerControllerGetProfileUserRaw(requestParameters: SignerControllerGetProfileUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Signer>> {
        if (requestParameters['userId'] == null) {
            throw new runtime.RequiredError(
                'userId',
                'Required parameter "userId" was null or undefined when calling signerControllerGetProfileUser().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/signer/profile/{userId}`.replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters['userId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SignerFromJSON(jsonValue));
    }

    /**
     */
    async signerControllerGetProfileUser(requestParameters: SignerControllerGetProfileUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Signer> {
        const response = await this.signerControllerGetProfileUserRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async signerControllerGetWitnessRaw(requestParameters: SignerControllerGetWitnessRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Witness>> {
        if (requestParameters['userId'] == null) {
            throw new runtime.RequiredError(
                'userId',
                'Required parameter "userId" was null or undefined when calling signerControllerGetWitness().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/signer/witness/{userId}`.replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters['userId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => WitnessFromJSON(jsonValue));
    }

    /**
     */
    async signerControllerGetWitness(requestParameters: SignerControllerGetWitnessRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Witness> {
        const response = await this.signerControllerGetWitnessRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async signerControllerPartialUpdateBusinessRaw(requestParameters: SignerControllerPartialUpdateBusinessRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Business>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling signerControllerPartialUpdateBusiness().'
            );
        }

        if (requestParameters['updateBusinessDTO'] == null) {
            throw new runtime.RequiredError(
                'updateBusinessDTO',
                'Required parameter "updateBusinessDTO" was null or undefined when calling signerControllerPartialUpdateBusiness().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/signer/business/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateBusinessDTOToJSON(requestParameters['updateBusinessDTO']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BusinessFromJSON(jsonValue));
    }

    /**
     */
    async signerControllerPartialUpdateBusiness(requestParameters: SignerControllerPartialUpdateBusinessRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Business> {
        const response = await this.signerControllerPartialUpdateBusinessRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async signerControllerPartialUpdateInhouseNotaryRaw(requestParameters: SignerControllerPartialUpdateInhouseNotaryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UpdateResponse>> {
        if (requestParameters['businessId'] == null) {
            throw new runtime.RequiredError(
                'businessId',
                'Required parameter "businessId" was null or undefined when calling signerControllerPartialUpdateInhouseNotary().'
            );
        }

        if (requestParameters['updateInhouseNotaries'] == null) {
            throw new runtime.RequiredError(
                'updateInhouseNotaries',
                'Required parameter "updateInhouseNotaries" was null or undefined when calling signerControllerPartialUpdateInhouseNotary().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/signer/inhouseNotary/{businessId}`.replace(`{${"businessId"}}`, encodeURIComponent(String(requestParameters['businessId']))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateInhouseNotariesToJSON(requestParameters['updateInhouseNotaries']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UpdateResponseFromJSON(jsonValue));
    }

    /**
     */
    async signerControllerPartialUpdateInhouseNotary(requestParameters: SignerControllerPartialUpdateInhouseNotaryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UpdateResponse> {
        const response = await this.signerControllerPartialUpdateInhouseNotaryRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async signerControllerPartialUpdateSignerRaw(requestParameters: SignerControllerPartialUpdateSignerRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Signer>> {
        if (requestParameters['signerUpdateDTO'] == null) {
            throw new runtime.RequiredError(
                'signerUpdateDTO',
                'Required parameter "signerUpdateDTO" was null or undefined when calling signerControllerPartialUpdateSigner().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/signer/signer/edit`,
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: SignerUpdateDTOToJSON(requestParameters['signerUpdateDTO']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SignerFromJSON(jsonValue));
    }

    /**
     */
    async signerControllerPartialUpdateSigner(requestParameters: SignerControllerPartialUpdateSignerRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Signer> {
        const response = await this.signerControllerPartialUpdateSignerRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async signerControllerPartialUpdateSignerParamRaw(requestParameters: SignerControllerPartialUpdateSignerParamRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Signer>> {
        if (requestParameters['userId'] == null) {
            throw new runtime.RequiredError(
                'userId',
                'Required parameter "userId" was null or undefined when calling signerControllerPartialUpdateSignerParam().'
            );
        }

        if (requestParameters['signerUpdateDTO'] == null) {
            throw new runtime.RequiredError(
                'signerUpdateDTO',
                'Required parameter "signerUpdateDTO" was null or undefined when calling signerControllerPartialUpdateSignerParam().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/signer/signer/edit/{userId}`.replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters['userId']))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: SignerUpdateDTOToJSON(requestParameters['signerUpdateDTO']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SignerFromJSON(jsonValue));
    }

    /**
     */
    async signerControllerPartialUpdateSignerParam(requestParameters: SignerControllerPartialUpdateSignerParamRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Signer> {
        const response = await this.signerControllerPartialUpdateSignerParamRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async signerControllerQuestionsIdologyRaw(requestParameters: SignerControllerQuestionsIdologyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<IdologyDTO>> {
        if (requestParameters['lastSsn4'] == null) {
            throw new runtime.RequiredError(
                'lastSsn4',
                'Required parameter "lastSsn4" was null or undefined when calling signerControllerQuestionsIdology().'
            );
        }

        if (requestParameters['dob'] == null) {
            throw new runtime.RequiredError(
                'dob',
                'Required parameter "dob" was null or undefined when calling signerControllerQuestionsIdology().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['lastSsn4'] != null) {
            queryParameters['lastSsn4'] = requestParameters['lastSsn4'];
        }

        if (requestParameters['dob'] != null) {
            queryParameters['dob'] = requestParameters['dob'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/signer/idology/questions`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IdologyDTOFromJSON(jsonValue));
    }

    /**
     */
    async signerControllerQuestionsIdology(requestParameters: SignerControllerQuestionsIdologyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<IdologyDTO> {
        const response = await this.signerControllerQuestionsIdologyRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async signerControllerReScanRaw(requestParameters: SignerControllerReScanRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['kbaBody'] == null) {
            throw new runtime.RequiredError(
                'kbaBody',
                'Required parameter "kbaBody" was null or undefined when calling signerControllerReScan().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/signer/scan`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: KbaBodyToJSON(requestParameters['kbaBody']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async signerControllerReScan(requestParameters: SignerControllerReScanRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.signerControllerReScanRaw(requestParameters, initOverrides);
    }

    /**
     */
    async signerControllerRemoveInhouseNotaryRaw(requestParameters: SignerControllerRemoveInhouseNotaryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UpdatedDTO>> {
        if (requestParameters['businessId'] == null) {
            throw new runtime.RequiredError(
                'businessId',
                'Required parameter "businessId" was null or undefined when calling signerControllerRemoveInhouseNotary().'
            );
        }

        if (requestParameters['removeNotary'] == null) {
            throw new runtime.RequiredError(
                'removeNotary',
                'Required parameter "removeNotary" was null or undefined when calling signerControllerRemoveInhouseNotary().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/signer/remove-inhouseNotary/{businessId}`.replace(`{${"businessId"}}`, encodeURIComponent(String(requestParameters['businessId']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
            body: RemoveNotaryToJSON(requestParameters['removeNotary']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UpdatedDTOFromJSON(jsonValue));
    }

    /**
     */
    async signerControllerRemoveInhouseNotary(requestParameters: SignerControllerRemoveInhouseNotaryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UpdatedDTO> {
        const response = await this.signerControllerRemoveInhouseNotaryRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async signerControllerScanResponseRaw(requestParameters: SignerControllerScanResponseRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<IdologyDTO>> {
        if (requestParameters['idologyIdNumber'] == null) {
            throw new runtime.RequiredError(
                'idologyIdNumber',
                'Required parameter "idologyIdNumber" was null or undefined when calling signerControllerScanResponse().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/signer/idology/scanResponse/{idologyIdNumber}`.replace(`{${"idologyIdNumber"}}`, encodeURIComponent(String(requestParameters['idologyIdNumber']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IdologyDTOFromJSON(jsonValue));
    }

    /**
     */
    async signerControllerScanResponse(requestParameters: SignerControllerScanResponseRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<IdologyDTO> {
        const response = await this.signerControllerScanResponseRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async signerControllerScanUrlRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ScanUrl>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/signer/idology/get-IdScanUrl`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ScanUrlFromJSON(jsonValue));
    }

    /**
     */
    async signerControllerScanUrl(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ScanUrl> {
        const response = await this.signerControllerScanUrlRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async signerControllerScanUrlIdologyRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<IdologyDTO>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/signer/idology/refresh-sacnUrl`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IdologyDTOFromJSON(jsonValue));
    }

    /**
     */
    async signerControllerScanUrlIdology(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<IdologyDTO> {
        const response = await this.signerControllerScanUrlIdologyRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async signerControllerSendSmsRaw(requestParameters: SignerControllerSendSmsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DefaultResponse>> {
        if (requestParameters['sMSDTO'] == null) {
            throw new runtime.RequiredError(
                'sMSDTO',
                'Required parameter "sMSDTO" was null or undefined when calling signerControllerSendSms().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/signer/sendSms/link`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SMSDTOToJSON(requestParameters['sMSDTO']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DefaultResponseFromJSON(jsonValue));
    }

    /**
     */
    async signerControllerSendSms(requestParameters: SignerControllerSendSmsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DefaultResponse> {
        const response = await this.signerControllerSendSmsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async signerControllerUpdateBusinessRaw(requestParameters: SignerControllerUpdateBusinessRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Business>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling signerControllerUpdateBusiness().'
            );
        }

        if (requestParameters['updateBusinessDTO'] == null) {
            throw new runtime.RequiredError(
                'updateBusinessDTO',
                'Required parameter "updateBusinessDTO" was null or undefined when calling signerControllerUpdateBusiness().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/signer/business/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateBusinessDTOToJSON(requestParameters['updateBusinessDTO']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BusinessFromJSON(jsonValue));
    }

    /**
     */
    async signerControllerUpdateBusiness(requestParameters: SignerControllerUpdateBusinessRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Business> {
        const response = await this.signerControllerUpdateBusinessRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async signerControllerUpdateInhouseNotaryRaw(requestParameters: SignerControllerUpdateInhouseNotaryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UpdateResponse>> {
        if (requestParameters['businessId'] == null) {
            throw new runtime.RequiredError(
                'businessId',
                'Required parameter "businessId" was null or undefined when calling signerControllerUpdateInhouseNotary().'
            );
        }

        if (requestParameters['updateInhouseNotaries'] == null) {
            throw new runtime.RequiredError(
                'updateInhouseNotaries',
                'Required parameter "updateInhouseNotaries" was null or undefined when calling signerControllerUpdateInhouseNotary().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/signer/inhouseNotary/{businessId}`.replace(`{${"businessId"}}`, encodeURIComponent(String(requestParameters['businessId']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateInhouseNotariesToJSON(requestParameters['updateInhouseNotaries']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UpdateResponseFromJSON(jsonValue));
    }

    /**
     */
    async signerControllerUpdateInhouseNotary(requestParameters: SignerControllerUpdateInhouseNotaryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UpdateResponse> {
        const response = await this.signerControllerUpdateInhouseNotaryRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async signerControllerUpdateSignerRaw(requestParameters: SignerControllerUpdateSignerRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Signer>> {
        if (requestParameters['signerUpdateDTO'] == null) {
            throw new runtime.RequiredError(
                'signerUpdateDTO',
                'Required parameter "signerUpdateDTO" was null or undefined when calling signerControllerUpdateSigner().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/signer/signer/edit`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: SignerUpdateDTOToJSON(requestParameters['signerUpdateDTO']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SignerFromJSON(jsonValue));
    }

    /**
     */
    async signerControllerUpdateSigner(requestParameters: SignerControllerUpdateSignerRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Signer> {
        const response = await this.signerControllerUpdateSignerRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async signerControllerUpdateSignerParamRaw(requestParameters: SignerControllerUpdateSignerParamRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Signer>> {
        if (requestParameters['userId'] == null) {
            throw new runtime.RequiredError(
                'userId',
                'Required parameter "userId" was null or undefined when calling signerControllerUpdateSignerParam().'
            );
        }

        if (requestParameters['signerUpdateDTO'] == null) {
            throw new runtime.RequiredError(
                'signerUpdateDTO',
                'Required parameter "signerUpdateDTO" was null or undefined when calling signerControllerUpdateSignerParam().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/signer/signer/edit/{userId}`.replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters['userId']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: SignerUpdateDTOToJSON(requestParameters['signerUpdateDTO']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SignerFromJSON(jsonValue));
    }

    /**
     */
    async signerControllerUpdateSignerParam(requestParameters: SignerControllerUpdateSignerParamRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Signer> {
        const response = await this.signerControllerUpdateSignerParamRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async signerControllerVeriffSessionRaw(requestParameters: SignerControllerVeriffSessionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<VeriffResPost>> {
        if (requestParameters['lastSsn4'] == null) {
            throw new runtime.RequiredError(
                'lastSsn4',
                'Required parameter "lastSsn4" was null or undefined when calling signerControllerVeriffSession().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['lastSsn4'] != null) {
            queryParameters['lastSsn4'] = requestParameters['lastSsn4'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/signer/veriffSession`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => VeriffResPostFromJSON(jsonValue));
    }

    /**
     */
    async signerControllerVeriffSession(requestParameters: SignerControllerVeriffSessionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<VeriffResPost> {
        const response = await this.signerControllerVeriffSessionRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async signerControllerVeriffSessionAttemptsRaw(requestParameters: SignerControllerVeriffSessionAttemptsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<VeriffRes>> {
        if (requestParameters['kbalogId'] == null) {
            throw new runtime.RequiredError(
                'kbalogId',
                'Required parameter "kbalogId" was null or undefined when calling signerControllerVeriffSessionAttempts().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/signer/veriffSessionAttempts/{kbalogId}`.replace(`{${"kbalogId"}}`, encodeURIComponent(String(requestParameters['kbalogId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => VeriffResFromJSON(jsonValue));
    }

    /**
     */
    async signerControllerVeriffSessionAttempts(requestParameters: SignerControllerVeriffSessionAttemptsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<VeriffRes> {
        const response = await this.signerControllerVeriffSessionAttemptsRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
