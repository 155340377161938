import { useEffect, useState } from "react";
import { useAuth } from "../../../utils/use-auth";
import { ESignSessionV2Api, Session, SessionApi } from "../../../api";
import { Heading, Stack, Text } from "@chakra-ui/react";
import NoDataFound from "../../commonComponents/LoadingComponents/NoDataFound";
import NotarySessionTable from "../../commonComponents/SessionTable/NotarySessionTable";
import ESignSessionTable from "../../commonComponents/SessionTable/ESignSessionTable";
import LoadingContent from "../../commonComponents/LoadingComponents/LoadingContent";

const ESignSessions = ({
  from = "notary",
}: {
  from?: "notary" | "witness" | "signer";
}) => {
  const { configuration } = useAuth();
  const [eSignSessions, setESignSessions] = useState<Session[]>([]);
  const [isFetching, setIsFetching] = useState(true);

  const startSession = (sessionId: number) => {
    console.log("sessionId", sessionId);
  };

  useEffect(() => {
    if (from === "signer") {
      new ESignSessionV2Api(configuration)
        .eSignSessionControllerGetSignerSessions()
        .then((res) => {
          setESignSessions(res);
          setIsFetching(false);
        })
        .catch((err) => {
          setESignSessions([]);
          setIsFetching(false);
        });
    } else {
      setIsFetching(true);
      new ESignSessionV2Api(configuration)
        .eSignSessionControllerGetEsignSessions()
        .then((res) => {
          setESignSessions(res);
          setIsFetching(false);
        })
        .catch((err) => {
          setESignSessions([]);
          setIsFetching(false);
        });
    }
  }, []);

  return (
    <Stack spacing={6} mx={"auto"} w={"100%"}>
      {isFetching ? (
        <LoadingContent text="Loading sessions" />
      ) : (
        <>
          <Heading
            fontSize={"22px"}
            lineHeight="30px"
            textAlign={"center"}
            mb={4}
            fontFamily="Oswald"
            fontWeight={600}
            color={"#2D3748"}
          >
            My E-Sign Sessions
          </Heading>
          <Text
            fontSize={"16px"}
            fontFamily={"Lato"}
            fontWeight={"400"}
            mb={"40px"}
          >
            These are your E-Sign sessions. you can prepare the documents for
            signers.
          </Text>

          {eSignSessions.length === 0 ? (
            <NoDataFound text="No Session Found" from="session" />
          ) : (
            <ESignSessionTable
              sessions={eSignSessions}
              startSession={startSession}
              from={from}
              isActive={true}
            />
          )}
        </>
      )}
    </Stack>
  );
};

export default ESignSessions;
