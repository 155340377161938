import moment from "moment";
import { Rnd } from "react-rnd";
import { ChangeEvent, useEffect, useMemo, useRef, useState } from "react";
import dots from "../../../assets/icons/dots.svg";
import { emitEvent } from "../../../utils/pdf-utils/emitEvent";
import deleteIcon from "../../../assets/icons/trash.svg";
import { SessionToolsProps } from "../../../store/zustandType";
import { useDimensionsStore } from "../../../store/dimensions";
import { useDocumentStore } from "../../../store/documentsStore";
import { EventAction, useAuditLogsStore } from "../../../store/auditLogs";
import {
  Image,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Select,
  Text,
} from "@chakra-ui/react";

const colors= ["teal", "tomato", "orange", "purple", "red", "blue", "gray"]
const randomColor = ()=>{
  return colors[Math.floor(Math.random()*colors.length)]
};

const LabelInput = ({ object, page, from, scale }: SessionToolsProps) => {
  const userId = localStorage.getItem("UserID");
  const inputRef = useRef<HTMLInputElement>(null);
  const ratio = useDimensionsStore((state) => state.ratio);
  const docId = useDocumentStore((state) => state.currentDocId);
  const [inputVal, setInputVal] = useState<string>(() => object.text ?? "");
  const [fontSize, setFontSize] = useState<number>(() => object?.size ?? 12);
  const [deleteAuditLog, updateAuditLog, sessionId, socket] = useAuditLogsStore(
    (state) => [
      state.deleteAuditLog,
      state.updateAuditLog,
      state.sessionId,
      state.socket,
    ]
  );

  const color = useMemo(()=>randomColor(),[]);

  useEffect(() => {
    setInputVal(object.text!);
    setFontSize(object.size!);
  }, [object.text, object.size]);

  const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    setInputVal(e.target.value);
  };

  const handleOnBlur = () => {
    if (!object.id) return;
    updateAuditLog({
      id: object.id!,
      selectedPage: page,
      type: object.type!,
      auditLog: {
        ...object,
        text: inputVal,
        size: fontSize,
      },
    });
    emitEvent({
      action: EventAction.update,
      event: object.type!,
      pageNumber: page,
      payload: { ...object, text: inputVal },
      sessionId: sessionId,
      socket: socket,
      docId,
    });
  };

  return (
    <Rnd
      disableDragging={
        from !== "notary" && from !== "business" && from !== "edit"
      }
      // enableResizing={{
      //   right: from === "notary" || from === "business",
      //   left: from === "notary" || from === "business",
      // }}
      enableResizing={from === "notary"}
      bounds="parent"
      scale={scale}
      style={{
        display: "flex",
        alignItems: "center",
        zIndex: 1,
        margin: 0,
        padding: 0,
        border: "1px dashed gray",
      }}
      size={{
        width: object.width
          ? (object.width * (object.zoomScale ?? 1) * (object.ratio ?? 1)) /
            ratio
          : 220,
        height: object.height
          ? (object.height * (object.zoomScale ?? 1) * (object.ratio ?? 1)) /
            ratio
          : 28,
      }}
      position={{
        x: object.x ? (object.x * (object.ratio ?? 1)) / ratio : 0,
        y: object.y ? (object.y * (object.ratio ?? 1)) / ratio : 0,
      }}
      onDragStop={(e, d) => {
        updateAuditLog({
          id: object.id!,
          selectedPage: page,
          type: object.type!,
          auditLog: {
            ...object,
            text: inputVal,
            x: (d.x / (object.ratio ?? 1)) * ratio,
            y: (d.y / (object.ratio ?? 1)) * ratio,
          },
        });
        emitEvent({
          action: EventAction.update,
          event: object.type!,
          pageNumber: page,
          payload: {
            ...object,
            text: inputVal,
            x: (d.x / (object.ratio ?? 1)) * ratio,
            y: (d.y / (object.ratio ?? 1)) * ratio,
          },
          sessionId: sessionId,
          socket: socket,
          docId,
        });
      }}
      onResizeStop={(e, direction, ref, d) => {
        updateAuditLog({
          id: object.id!,
          selectedPage: page,
          type: object.type!,
          auditLog: {
            ...object,
            width: ref.clientWidth,
            height: ref.clientHeight,
          },
        });
        emitEvent({
          action: EventAction.update,
          event: object.type!,
          pageNumber: page,
          payload: {
            ...object,
            width: ref.clientWidth,
            height: ref.clientHeight,
          },
          sessionId: sessionId,
          socket: socket,
          docId,
        });
      }}
    >
      <Text
        style={{
          position: "absolute",
          top: "-25px",
          left: "0px",
          backgroundColor: color,
          color: "white",
          padding: "0px 5px",
          borderRadius: "5px",
        }}
      >
        {object?.toBeSignedBy}
      </Text>
      <input
        ref={inputRef}
        style={{
          backgroundColor: "transparent",
          fontSize: `${object?.size ?? fontSize}px`,
          fontFamily: `${object.fontFamily}`,
          width: "95%",
          height: "100%",
          margin: 0,
          padding: 0,
          wordBreak: "break-all",
        }}
        draggable="false"
        // onFocus={(e) => e.target.select()}
        type="text"
        value={inputVal}
        onChange={(e) => handleOnChange(e)}
        onBlur={() => handleOnBlur()}
        disabled={
          object.toBeSignedByUserId !== Number(userId) &&
          from !== "notary" &&
          from !== "business" && from !== "edit"
        }
      />

      {from === "notary" || from === "business" || from === "edit" ? (
        <>
          {object.type === "text" ? (
            <Popover>
              <PopoverTrigger>
                <button
                  style={{
                    height: "20px",
                    width: "30px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Image
                    onClick={(e) => {
                      console.log("clicked");
                    }}
                    src={dots}
                    alt="options icon"
                    height={4}
                    width={4}
                  />
                </button>
              </PopoverTrigger>
              <PopoverContent px={2} py={1}>
                <PopoverArrow />
                <PopoverBody>
                  <Text fontWeight={500}>Font Size : </Text>
                  <NumberInput
                    max={30}
                    min={12}
                    step={2}
                    mt={1}
                    value={fontSize ?? 12}
                    clampValueOnBlur={false}
                    onChange={(val) => {
                      setFontSize(Number(val));
                      updateAuditLog({
                        id: object.id!,
                        selectedPage: page,
                        type: object.type!,
                        auditLog: {
                          ...object,
                          size: Number(val),
                        },
                      });
                      emitEvent({
                        action: EventAction.update,
                        event: object.type!,
                        pageNumber: page,
                        payload: {
                          ...object,
                          text: inputVal,
                          size: Number(val),
                        },
                        sessionId: sessionId,
                        socket: socket,
                        docId,
                      });
                    }}
                  >
                    <NumberInputField />
                    <NumberInputStepper>
                      <NumberIncrementStepper />
                      <NumberDecrementStepper />
                    </NumberInputStepper>
                  </NumberInput>

                  <Text mt={2} fontWeight={500}>
                    Font Family :{" "}
                  </Text>
                  <Select
                    my={1}
                    placeholder="Font Family"
                    onChange={(e) => {
                      console.log(e.target.value);
                      updateAuditLog({
                        id: object.id!,
                        selectedPage: page,
                        type: object.type!,
                        auditLog: {
                          ...object,
                          fontFamily: e.target.value,
                        },
                      });
                      emitEvent({
                        action: EventAction.update,
                        event: object.type!,
                        pageNumber: page,
                        payload: {
                          ...object,
                          fontFamily: e.target.value,
                        },
                        sessionId: sessionId,
                        socket: socket,
                        docId,
                      });
                    }}
                  >
                    <option value="Helvetica">Helvetica</option>
                    <option value="TimesRoman">TimesRoman</option>
                    <option value="Courier">Courier</option>
                  </Select>
                </PopoverBody>
              </PopoverContent>
            </Popover>
          ) : (
            <Popover isOpen={false}>
              <PopoverTrigger>
                <Image
                  draggable="false"
                  onClick={(e) => e.stopPropagation()}
                  src={dots}
                  alt="options icon"
                  height={4}
                  width={4}
                />
              </PopoverTrigger>
              <PopoverContent>
                <PopoverArrow />

                <PopoverBody>
                  <div style={{ marginInline: "8px" }}>
                    <Text fontWeight={500}>Date Format</Text>
                    <Select
                      my={1}
                      placeholder="Select Format"
                      onChange={(e) => {
                        if (e.target.value) {
                          setInputVal(e.target.value);
                        }
                      }}
                    >
                      <option
                        value={new Intl.DateTimeFormat("en-US").format(
                          new Date()
                        )}
                      >
                        {new Intl.DateTimeFormat("en-US").format(new Date())}
                      </option>
                      <option
                        value={new Intl.DateTimeFormat("en-US", {
                          dateStyle: "full",
                        }).format(new Date())}
                      >
                        {new Intl.DateTimeFormat("en-US", {
                          dateStyle: "full",
                        }).format(new Date())}
                      </option>

                      <option
                        value={moment(new Date()).format("Do MMMM, __YY")}
                      >
                        {moment(new Date()).format("Do MMMM, __YY")}
                      </option>

                      {/* <option
                        value={`${moment(new Date()).format(
                          "Do"
                        )} day of ${moment(new Date()).format("MMMM, YYYY")}`}
                      >
                        {moment(new Date()).format("Do")} day of{" "}
                        {moment(new Date()).format("MMMM, YYYY")}
                      </option> */}
                    </Select>
                  </div>
                </PopoverBody>
              </PopoverContent>
            </Popover>
          )}
          <div
            style={{
              cursor: "pointer",
              position: "absolute",
              left: "100%",
              height: "30px",
              width: "30px",
            }}
            onClick={(e) => {
              e.stopPropagation();
              deleteAuditLog({
                id: object.id!,
                selectedPage: page,
                type: object.type!,
                payload: object,
              });
              emitEvent({
                action: EventAction.delete,
                event: object.type!,
                pageNumber: page,
                payload: object,
                sessionId: sessionId,
                socket: socket,
                docId,
              });
            }}
          >
            <Image src={deleteIcon} alt="delete icon" height={6} width={6} />
          </div>
        </>
      ) : null}
    </Rnd>
  );
};
export default LabelInput;
