export const BASE_URL = `https://${process.env.REACT_APP_HOST}`;
export const WS_URL = `wss://${process.env.REACT_APP_HOST}`;
export const LIVEKIT_URL = process.env.REACT_APP_LIVEKIT_URL;

// export const BASE_URL = "http://192.168.1.52:3000";
// export const WS_URL = "http://192.168.1.52:3000";
// export const LIVEKIT_URL = "wss://notarize-genie-t1nqx5x9.livekit.cloud";

export const COLORS = {
  gold: "#D5AD36",
  blue : "#d8e7f8"
};
// console.log("test");
