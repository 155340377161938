//@ts-nocheck
import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Button,
  Divider,
  Flex,
  Text,
} from "@chakra-ui/react";
import * as Yup from "yup";
import { useMemo, useState } from "react";
import { Form, Formik } from "formik";
import { StarIcon } from "@chakra-ui/icons";
import { useNavigate } from "react-router-dom";
import "./participants.css";
import NeedWitness from "./NeedWitness";
import SignersDetail from "./SignersDetail";
import MediatoresDetail from "./MediatoresDetail";
import { useSessionCreate } from "../../../../store/store";
import useNotification from "../../../../utils/useNotification";
import { IMediator, IWitness } from "../../../../store/zustandType";
import {
  noValidation,
  allfieldsValidations,
  fourFieldsValidations,
  threeFieldsValidations,
} from "../../../../utils/validations";

const Participants = ({
  nextNavigationPath,
  from,
  prevNavigationPath,
}: {
  nextNavigationPath: string;
  from: string;
  prevNavigationPath?: string;
}) => {
  const navigate = useNavigate();
  const { notification } = useNotification();
  const [errMsg, setErrMsg] = useState<string>("");
  const { sessionDetail, setSessionDetails } = useSessionCreate();

  const isEsignSession = useMemo(
    () => sessionDetail?.isEsign,
    [sessionDetail?.isEsign]
  );

  return (
    <>
      <Alert status="info" mb={4} mt={8}>
        <AlertIcon />
        <AlertDescription fontSize={"14px"} lineHeight={"5"}>
          <AlertTitle>Note!</AlertTitle>
          We have placed a limit on all sessions, you may add up to 10
          participants in a single session this includes, all signers,
          witnesses, mediators and the notary.
        </AlertDescription>
      </Alert>

      <Text
        fontWeight={600}
        fontSize={{ base: "20px", md: "20px", lg: "20px" }}
        color={"#000080"}
        p={"15px 0"}
      >
        <StarIcon mx={1} mb={1} /> Primary Signer
      </Text>
      <Formik
        initialValues={{
          signers: sessionDetail?.signers,
          mediators: sessionDetail?.mediatores as IMediator[],
          witnesses: sessionDetail?.witnesses as IWitness[],
        }}
        // enableReinitialize
        validationSchema={Yup.object({
          signers:
            from === "signer" ? allfieldsValidations : fourFieldsValidations,
          witnesses:
            sessionDetail?.needWitness === "yes"
              ? fourFieldsValidations
              : noValidation,
          mediators: sessionDetail?.needMediator
            ? threeFieldsValidations
            : noValidation,
        })}
        onSubmit={(values) => {
          const totalParticipants =
            values?.signers.length +
            Number(sessionDetail?.witnessesCount) +
            values.witnesses.length +
            values.mediators.length;
          // check if same email is used more than once
          const users = [
            ...values["signers"]?.map((s) => ({
              email: s?.email,
              role: "signer",
            })),
            ...(sessionDetail.needMediator
              ? values["mediators"]?.map((m) => ({
                  email: m?.email,
                  role: "mediator",
                }))
              : []),
            ...(sessionDetail.needWitness === "yes"
              ? values["witnesses"]?.map((w) => ({
                  email: w?.email,
                  role: "witness",
                }))
              : []),
          ];
          let usersWithSameEmail = { flag: false, role: "" };
          let emails: string[] = [];
          for (const user of users) {
            if (emails.includes(user?.email)) {
              usersWithSameEmail = {
                flag: true,
                role: user?.role,
              };
              break;
            } else {
              emails.push(user?.email);
            }
          }
          if (usersWithSameEmail.flag) {
            notification(
              "warn",
              "can not use same email address for multiple participants"
            );
          } else if (totalParticipants > 10) {
            setErrMsg(
              "You can add maximum 10 participants in a session including all signers, notary and witnesses"
            );
          } else {
            setErrMsg("");
            setSessionDetails({
              ...sessionDetail,
              signers: values?.signers,
              mediatores: values?.mediators,
              witnesses: values?.witnesses,
              primarySignerName: `${values?.signers?.[0]?.firstName} ${values?.signers?.[0]?.lastName}`,
            });
            navigate(nextNavigationPath);
          }
        }}
        render={({
          values,
          handleSubmit,
          handleBlur,
          handleChange,
          setFieldValue,
        }) => (
          <Form onSubmit={handleSubmit}>
            <SignersDetail
              values={values}
              from={from}
              handleChange={handleChange}
              setFieldValue={setFieldValue}
              handleBlur={handleBlur}
              isEsignSession={isEsignSession}
            />


            {isEsignSession ? null : (
              <>
              <Divider mt={4} />
                <NeedWitness
                  values={values}
                  from={from}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                />

                <Divider mt={4} />

                <MediatoresDetail values={values} from={from} />
              </>
            )}

            {errMsg !== "" && (
              <Text color={"red"} fontSize={14}>
                *{errMsg}
              </Text>
            )}

            <Flex marginTop={5} justifyContent={"flex-end"}>
              <Button
                mx={2}
                style={{ padding: "10px 30px" }}
                width={"fit-content"}
                onClick={() => {
                  if (prevNavigationPath) {
                    navigate(prevNavigationPath);
                  }
                }}
              >
                Prev
              </Button>

              <Button
                type="submit"
                style={{
                  background: "#2D3748",
                  color: "#fff",
                  padding: "10px 30px",
                }}
                width="fit-content"
              >
                Next
              </Button>
            </Flex>
          </Form>
        )}
      />
    </>
  );
};

export default Participants;
